import MonetCard from "components/tailwind/MonetCard";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import MonetCardTitle from "../MonetCardTitle";

type AreaChartProps = {
  title: string;
  series: Array<any>;
  xaxisLabel: string;
  yaxisLabel: string;
};

const AreaChart: React.FC<AreaChartProps> = ({ series, xaxisLabel, yaxisLabel, title }) => {
  const options: ApexCharts.ApexOptions = useMemo(() => {
    return {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#162be4"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        title: {
          text: xaxisLabel,
        },
        labels: {
          format: "MMM",
          showDuplicates: false,
        },
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        title: {
          text: yaxisLabel,
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any, { seriesIndex, dataPointIndex, w }: any) {
            return w.config.series[seriesIndex].data[dataPointIndex].label;
          },
        },
        x: {
          format: "dd MMM yyyy",
        },
      },
      noData: {
        text: "No data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -15,
      },
    };
  }, []);

  return (
    <MonetCard>
      <MonetCardTitle>{title}</MonetCardTitle>
      <ReactApexChart options={options} series={series} type="area" width="100%" height={250} />
    </MonetCard>
  );
};

export default AreaChart;
