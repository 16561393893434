import TailwindDialog from "components/tailwind/headlessTailwind/TailwindDialog";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Campaign } from "shared/types/eep.contract.types";
import { toast } from "sonner";
import { CAMPAIGNS_LIST_ALL_PATH, CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import { useDeleteCampaignMutation } from "views/campaigns/graphql/mutations.generated";

type DeleteCampaignModalProps = {
  campaign?: Campaign;
};

const DeleteCampaignModal: React.FC<DeleteCampaignModalProps> = ({ campaign }) => {
  const navigate = useNavigate();
  const { user } = useSessionProvider();
  const [deleteCampaign] = useDeleteCampaignMutation();

  const handleDelete = useCallback((): Promise<boolean> => {
    return new Promise((resolve) => {
      if (campaign) {
        deleteCampaign({
          variables: {
            campaignId: campaign.campaignId,
            partnerId: campaign.partnerId,
          },
        })
          .then(() => {
            navigate(CAMPAIGNS_PATH + CAMPAIGNS_LIST_ALL_PATH);
            toast.success("Success", { description: "Campaign has been deleted" });
          })
          .catch((err) => {
            toast.error("Request failed", { description: err.message });
          })
          .finally(() => {
            resolve(true);
          });
      }
    });
  }, [campaign]);

  return (
    <TailwindDialog type="danger" title="Delete campaign" submitButtonColor="red-solid" onConfirmCallback={() => handleDelete()}>
      Are you sure? You will be permanently deleting campaign <span className="font-semibold">{campaign && campaign.campaignName}</span>. This action can not be undone
    </TailwindDialog>
  );
};

export default DeleteCampaignModal;
