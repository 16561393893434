import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { ReactComponent as UpDownChevron } from "assets/tailwind/icons/chevron-up-down.svg";
import { ReactComponent as Tick } from "assets/tailwind/icons/tick.svg";
import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { ControllerRenderProps, Noop, RefCallBack } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import MonetToolTip from "../MonetTooltip";

type selectedValue =
  | Array<{
      label: string;
      value: string | number;
    }>
  | {
      label: string;
      value: string | number;
    };

type TailwindListBoxProps = VariantProps<typeof variants> & {
  options: Array<{
    label: string;
    value: string | number;
  }>;
  inputProps: Omit<ControllerRenderProps, "ref" | "onBlur"> & {
    ref?: RefCallBack;
    onBlur?: Noop;
  };
  placeholder?: string;
  secondaryLabel?: React.ReactNode;
  multiple?: boolean;
  label?: string;
  dropdownClassName?: string;
  disabled?: boolean;
  isOptional?: boolean;
  tooltip?: string;
  error?: string;
};

const TailwindSelectInput: React.FC<TailwindListBoxProps> = ({
  options,
  placeholder,
  size,
  isOptional,
  tooltip,
  label,
  error,
  inputProps,
  disabled,
  dropdownClassName,
  multiple,
  secondaryLabel,
}) => {
  const [selected, setSelected] = useState<selectedValue>();

  const errorStyling = error ? "border-red-500 ring ring-red-100 ring-offset-0 focus:ring-red-500 focus:ring focus:ring-red-100 focus:outline-offset-0" : undefined;

  useEffect(() => {
    let initialValue;
    if (multiple) {
      initialValue = inputProps.value?.map((value: string) => {
        return options.find((option) => option.value === value);
      });
    } else {
      initialValue = options.find((option) => option.value === inputProps.value);
    }
    setSelected(initialValue);
  }, [inputProps.value, options]);

  const handleChange = (value: selectedValue) => {
    if (multiple) {
      inputProps.onChange((value as Array<{ label: string; value: string }>).map((item) => item.value));
    } else {
      inputProps.onChange((value as { label: string; value: string }).value);
    }
    setSelected(value);
  };

  const listboxOptions = useMemo(() => {
    return options.map((option) => (
      <ListboxOption
        key={option.label}
        value={option}
        className="group flex justify-between cursor-pointer items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-gray-100 text-gray-800"
      >
        <div className="text-sm">{option.label}</div>
        <Tick className="invisible group-data-[selected]:visible w-5 h-5 opacity-50" />
      </ListboxOption>
    ));
  }, [options]);

  const currentValueLabel = useMemo(() => {
    if (multiple) {
      return (selected as Array<{ label: string; value: string }>)?.map((value) => value.label).join(", ");
    } else {
      return (selected as { label: string; value: string })?.label;
    }
  }, [selected, multiple]);

  return (
    <div className="w-full">
      {(label || secondaryLabel) && (
        <div className="flex flex-col sm:flex-row justify-between mb-2 text-sm w-full">
          <label htmlFor={label} className="block font-semibold text-gray-800 dark:text-white">
            {label}
            {isOptional && <sup className="text-gray-600 font-light">(Optional)</sup>}
            {tooltip && <MonetToolTip text={tooltip} />}
          </label>
          <div className="block text-sm text-gray-500 dark:text-neutral-500">{secondaryLabel}</div>
        </div>
      )}
      <Listbox {...inputProps} onChange={handleChange} value={selected} disabled={disabled} multiple={multiple}>
        {({ disabled }) => (
          <>
            <ListboxButton className={twMerge(clsx(variants({ size }), "truncate pe-8 w-full", disabled && "bg-gray-50 cursor-default text-gray-500", errorStyling))}>
              <span className="">{currentValueLabel ?? placeholder ?? "Please select"}</span>
              <div className="group absolute top-0 right-2.5 h-full flex items-center text-gray-800">
                <UpDownChevron className={twMerge(clsx("text-gray-500 w-4 h-4"))} />
              </div>
            </ListboxButton>
            <ListboxOptions
              anchor="bottom end"
              transition
              className={clsx(
                "w-[var(--button-width)] rounded-lg border border-gray-200 bg-white mt-2 text-gray-800 [--anchor-gap:var(--spacing-5)] overflow-hidden shadow-sm",
                "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
                dropdownClassName,
              )}
            >
              <div className="max-h-[300px] overflow-y-auto p-2">{listboxOptions}</div>
            </ListboxOptions>
          </>
        )}
      </Listbox>
      {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default TailwindSelectInput;

const variants = cva("relative block w-full text-left text-sm rounded-lg bg-white border border-gray-200", {
  variants: {
    size: {
      small: "py-2 px-3.5",
      default: "py-3.5 px-4 h-[50px]",
      large: "py-6 px-5",
    },
  },
  defaultVariants: {
    size: "default",
  },
});
