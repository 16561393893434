import { Paper, Stack, styled } from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridFooter,
  GridFooterContainer,
  GridToolbarContainer,
  GridToolbarExport,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import clsx from "clsx";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import MonetLoadingSpinner from "components/tailwind/MonetLoadingSpinner";
import MonetMenuDropdown from "components/tailwind/MonetMenuDropdown";
import React from "react";

type CustomDataGridProps = {
  title?: string;
  isDataLoading?: boolean;
  showAsUnstyledCard?: boolean;
  enableExport?: boolean;
  hasFooter?: boolean;
  toolbarActionItems?: Array<{
    key: string;
    element: React.ReactNode;
    isPrimaryAction?: boolean;
    visible?: boolean;
    hideInMobileMenu?: boolean;
  }>;
};

const StyledDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== "hasFooter",
})<{ hasFooter: boolean }>(({ hasFooter, theme, rowCount }) => ({
  border: "none",
  "--DataGrid-overlayHeight": rowCount ? undefined : "200px",

  "& .MuiButtonBase-root.Mui-disabled": {
    color: theme.palette.grey[400],
  },
  "& .MuiDataGrid-main, .MuiDataGrid-footerContainer": {
    backgroundColor: "#ffffff",
    border: "none",
  },
  "& .MuiDataGrid-row": {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[50],
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.common.white,
    },
  },
  "& .MuiDataGrid-footerContainer": {
    display: hasFooter ? "block" : "none",
  },
}));

const CustomToolbar = (items: CustomDataGridProps["toolbarActionItems"], enableExport: boolean, title?: string) => {
  const apiRef = useGridApiContext();

  const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef);

  const handleExport = (options: GridCsvExportOptions) => {
    apiRef.current.exportDataAsCsv(options);
  };

  const primaryAction = items?.find((item) => item.isPrimaryAction);

  const showDropdown = primaryAction && items?.length === 1 ? false : true;

  if ((!items || items.length <= 0) && !enableExport && !title) {
    return null;
  }

  return (
    <GridToolbarContainer sx={{ p: 0, pb: 2 }}>
      <div className="flex w-full justify-between gap-2">
        <MonetCardTitle>{title}</MonetCardTitle>
        <div className="flex gap-2">
          {primaryAction && primaryAction.visible && <div className="hidden sm:block">{primaryAction.element}</div>}
          {(showDropdown || enableExport) && (
            <MonetMenuDropdown>
              {items &&
                items?.length > 0 &&
                items.map(
                  (item) =>
                    item.visible &&
                    !item.hideInMobileMenu && (
                      <div className={clsx("block", item.isPrimaryAction && "sm:hidden")} key={item.key}>
                        {item.element}
                      </div>
                    ),
                )}
              {enableExport && (
                <MonetDropdownMenuItem onClick={() => handleExport({ getRowsToExport: getFilteredRows, fileName: "monet-data-table-export" })} id="data-table-export">
                  Export table to CSV
                </MonetDropdownMenuItem>
              )}
            </MonetMenuDropdown>
          )}
        </div>
      </div>
    </GridToolbarContainer>
  );
};

const CustomDataGrid: React.FC<DataGridProps & CustomDataGridProps> = ({
  isDataLoading = false,
  showAsUnstyledCard = false,
  enableExport = true,
  hasFooter = true,
  title,
  toolbarActionItems,
  ...props
}) => {
  return (
    <MonetCard className="h-full" variant={showAsUnstyledCard ? "unstyled" : "styled"}>
      <Paper
        sx={{
          flex: 1,
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        {isDataLoading ? (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <MonetLoadingSpinner size="default" />
          </Stack>
        ) : (
          <StyledDataGrid
            {...props}
            hasFooter={hasFooter}
            autoPageSize={true}
            disableRowSelectionOnClick
            slots={{
              toolbar: () => CustomToolbar(toolbarActionItems, enableExport, title),
            }}
            components={{
              Footer: () => (
                <GridFooterContainer sx={{ py: 2 }}>
                  <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={{ utf8WithBom: true }} />
                  <GridFooter
                    sx={{
                      border: "none",
                    }}
                  />
                </GridFooterContainer>
              ),
              ...props.components,
            }}
          />
        )}
      </Paper>
    </MonetCard>
  );
};

export default CustomDataGrid;
