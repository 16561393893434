import { ApolloProvider } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SystemTimeout from "components/SystemTimeout/SystemTimeout";
import useApolloClient from "contexts/apollo/useApolloClient";
import { AppProvider } from "contexts/AppProvider";
import { closeSnackbar, SnackbarKey, SnackbarProvider } from "notistack";
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppRoutes from "routing/AppRoutes";
import { SessionProvider } from "./contexts/SessionProvider";
import theme from "./theme/theme";
import { ModalProvider } from "contexts/ModalProvider";

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const SnackbarCloseButton = (snackbarId: SnackbarKey) => {
  return (
    <IconButton color="inherit" onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon />
    </IconButton>
  );
};
const App: React.FC = () => {
  const myTheme = createTheme(theme);
  const client = useApolloClient();

  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
      <ThemeProvider theme={myTheme}>
        <ApolloProvider client={client}>
          <SessionProvider>
            <SnackbarProvider maxSnack={3} autoHideDuration={null} anchorOrigin={{ horizontal: "center", vertical: "top" }} action={SnackbarCloseButton}>
              <AppProvider>
                <ModalProvider>
                  <SystemTimeout />
                  <AppRoutes />
                </ModalProvider>
              </AppProvider>
            </SnackbarProvider>
          </SessionProvider>
        </ApolloProvider>
      </ThemeProvider>
  );
};
export default App;
