import { MoneyStorage } from "@monet-money/money-type";
import { MonetBadgeVariants } from "components/tailwind/MonetBadge";

export type InvoiceFundingStatus = "SUBMITTED" | "ACTIVE" | "REJECTED" | "SETTLED";

export type Invoice = {
  invoiceId: string;
  campaignId: string;
  partnerId: string;
  funding?: {
    request: {
      requestBy: string;
      requestDate: string;
      processedBy?: string;
      processedDate?: string;
    };
    fundingStatus: InvoiceFundingStatus;
    principalAmount?: MoneyStorage;
    advanceRate?: number;
    notes?: {
      note: string;
      createdAt: string;
      author: string;
    };
    underwritingNotes: {
      note: string;
      createdAt: string;
      author: string;
    };
    experianRiskScore?: string;
    wiserfundingRiskScore?: string;
  };
  invoiceStatus: "DRAFT" | "BILLED" | "OVERDUE" | "SETTLED";
  invoiceDocument: string;
  invoiceNumber: string;
  deliverables: number[];
  dueDate: string;
  settlement?: {
    date: string;
    settledBy: string;
    daysLate: number;
    dailyLateFee: number;
    totalLateFee: MoneyStorage;
    principal: MoneyStorage;
    earlyPayFees: MoneyStorage;
    partnerFees: MoneyStorage;
    residual: MoneyStorage;
    checksToZero: boolean;
    totalEarlyPayFees: MoneyStorage;
    notes: string;
  };
  invoiceValue: MoneyStorage;
  termsAndConditions: {
    accepted: boolean;
    timestamp: string;
  };
  createdAt: string;
  updatedAt: string;
};

export const getInvoiceStatusSettings = (status: Invoice["invoiceStatus"] | InvoiceFundingStatus): { color: MonetBadgeVariants["variant"] } => {
  switch (status) {
    case "DRAFT":
      return {
        color: "gray",
      };
    case "ACTIVE":
      return {
        color: "green",
      };
    case "BILLED":
    case "SUBMITTED":
      return {
        color: "blue",
      };
    case "OVERDUE":
      return {
        color: "yellow",
      };
    case "REJECTED":
      return {
        color: "red",
      };
    case "SETTLED": {
      return {
        color: "purple",
      };
    }
    default:
      return {
        color: "gray",
      };
  }
};
