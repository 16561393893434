import { yupResolver } from "@hookform/resolvers/yup";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetPhoneNumberInput from "components/tailwind/form/MonetPhoneNumberInput";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { BorderlessCountry } from "shared/generated/ep3.graphql.types";
import { payeeSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { PayeeField, getPayeeRequiredFieldKeys } from "utils/Payouts";
import { toSentenceCase } from "utils/strings";
import BeneficiaryRequiredFields from "views/payouts/createPayout/components/BeneficiaryRequiredFields";
import { useCreatePayeeMutation } from "views/payouts/graphql/mutation.generated";
import { useGetPayeeFieldsLazyQuery, useGetSupportedCountriesQuery } from "views/payouts/graphql/queries.generated";

type AddPayeeDrawerProps = {
  payoutType: "WITHDRAWAL" | "VENDOR";
  refetchPayees(): void;
};

const AddPayeeDrawer: React.FC<AddPayeeDrawerProps> = ({ payoutType, refetchPayees }) => {
  const [supportedCountries, setSupportedCountries] = useState<BorderlessCountry[]>([]);
  const [payeeFields, setPayeeFields] = useState<PayeeField[]>([]);

  const { data: getCountriesData } = useGetSupportedCountriesQuery({ nextFetchPolicy: "network-only" });
  const [getPayeeFields, { data: getPayeeFieldsData }] = useGetPayeeFieldsLazyQuery({ nextFetchPolicy: "network-only" });
  const [createPayee] = useCreatePayeeMutation();

  const { closeModal } = useModal();

  useEffect(() => {
    if (getCountriesData) {
      setSupportedCountries(getCountriesData.getSupportedCountries ?? []);
    }
  }, [getCountriesData]);

  // Set fields
  useEffect(() => {
    if (getPayeeFieldsData) {
      const response = JSON.parse(getPayeeFieldsData.getPayeeFields) as Components.Schemas.PayeeRequiredInformation;
      const fields = getPayeeRequiredFieldKeys(response.fields);
      setPayeeFields(fields);
    }
  }, [getPayeeFieldsData]);

  const terminology = useMemo(() => {
    return payoutType === "VENDOR" ? "vendor" : "bank account";
  }, [payoutType]);

  const onSubmit = async (formData: any) => {
    if (formData) {
      const payload = {
        ...formData,
        payeeType: payoutType,
      };
      try {
        await createPayee({
          variables: {
            payee: JSON.stringify(payload),
          },
        }).then(() => {
          toast.success("Success", {
            description: `${toSentenceCase(terminology)} has been created. You can now select this ${toSentenceCase(terminology)} from the dropdown list`,
          });
          resetComponent();
          refetchPayees();
        });
      } catch (error: any) {
        toast.error("Request failed", { description: `Failed to create ${terminology}. ` + error.message });
      }
    }
  };

  const methods = useForm<any>({
    mode: "onTouched",
    resolver: yupResolver(payeeSchema),
  });

  const resetComponent = () => {
    closeModal();
    methods.reset();
  };

  const selectedCountryCode = methods.watch("country");
  const selectedAccountType = methods.watch("accountType");

  useEffect(() => {
    if (selectedCountryCode && selectedAccountType) {
      getPayeeFields({ variables: { countryCode: selectedCountryCode, accountType: selectedAccountType } });
    }
  }, [selectedCountryCode, selectedAccountType]);

  const currenciesOptions = useMemo(() => {
    if (selectedCountryCode) {
      const supportedCurrencies = supportedCountries.find((x) => x.code == selectedCountryCode)?.currency ?? [];
      return supportedCurrencies.map((x) => ({ label: x, value: x }));
    }
    return [];
  }, [supportedCountries, selectedCountryCode]);

  const countrySelectOptions = useMemo(() => {
    return supportedCountries.map((x) => ({
      label: x.name,
      value: x.code,
    }));
  }, [supportedCountries, selectedCountryCode]);

  const accountTypeOptions = useMemo(() => {
    const options = [{ label: "Business account", value: "BUSINESS" }];

    if (payoutType === "VENDOR") {
      options.push({ label: "Personal account", value: "PERSONAL" });
    }

    return options;
  }, [payoutType]);

  const renderBeneficiaryFields = useMemo(() => {
    return <BeneficiaryRequiredFields requiredFields={payeeFields} />;
  }, [payeeFields]);

  return (
    <TailwindDrawer
      title={payoutType === "VENDOR" ? "Vendor details" : "Bank account details"}
      subtitle="Fill out the details below to add a new payee"
      onCloseCallback={resetComponent}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-2">
          <div className="flex flex-col gap-4">
            <MonetInput
              {...methods.register("firstName")}
              label="First name"
              error={methods.formState.errors.firstName?.message as string}
              disabled={methods.formState.isSubmitting}
              id="firstName"
            />
            <MonetInput
              {...methods.register("email")}
              label="Email"
              error={methods.formState.errors.email?.message as string}
              disabled={methods.formState.isSubmitting}
              id="email"
            />
            <MonetInput
              {...methods.register("payeeAlias")}
              label="Alias"
              error={methods.formState.errors.payeeAlias?.message as string}
              disabled={methods.formState.isSubmitting}
              id="payeeAlias"
              tooltip="This alias is used to uniquely identify the payee within MONET"
            />
            <Controller
              control={methods.control}
              name="accountType"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Account type"
                  placeholder="Please select..."
                  options={accountTypeOptions}
                  error={methods.formState.errors.accountType?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
              )}
            />
            <Controller
              control={methods.control}
              name="country"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Country of bank account"
                  placeholder="Please select..."
                  options={countrySelectOptions}
                  error={methods.formState.errors.country?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
              )}
            />
            <Controller
              control={methods.control}
              name="currency"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Account Currency"
                  placeholder="Please select..."
                  options={currenciesOptions}
                  error={methods.formState.errors.currency?.message as string}
                  disabled={methods.formState.isSubmitting || !selectedCountryCode}
                />
              )}
            />
            <MonetPhoneNumberInput label="Mobile number" name="phoneNumber" error={methods.formState.errors.phoneNumber?.message as string} />
            {renderBeneficiaryFields}
          </div>
          <MonetDrawerButtons
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isValid}
            loading={methods.formState.isSubmitting}
            onCancelCallback={resetComponent}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default AddPayeeDrawer;
