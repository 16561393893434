import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAggregationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAggregationQuery = { __typename?: 'Query', getAggregation?: { __typename?: 'Aggregation', partnerId: string, pendingPayouts?: number | null, updatedAt: string, fundingBalance?: { __typename?: 'MoneyStorage', currency: Types.Currency, amount: number } | null, paymentsCompleted?: Array<{ __typename?: 'PaymentsCompleted', date: string, value: number } | null> | null, totalRevenue?: Array<{ __typename?: 'TotalRevenue', date: string, value: { __typename?: 'MoneyStorage', currency: Types.Currency, amount: number } } | null> | null } | null };

export type GetCampaignsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCampaignsQuery = { __typename?: 'Query', getCampaigns: any };

export type GetPayoutsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPayoutsQuery = { __typename?: 'Query', getPayouts: any };


export const GetAggregationDocument = gql`
    query getAggregation {
  getAggregation {
    partnerId
    fundingBalance {
      currency
      amount
    }
    paymentsCompleted {
      date
      value
    }
    pendingPayouts
    totalRevenue {
      date
      value {
        currency
        amount
      }
    }
    updatedAt
  }
}
    `;

/**
 * __useGetAggregationQuery__
 *
 * To run a query within a React component, call `useGetAggregationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAggregationQuery(baseOptions?: Apollo.QueryHookOptions<GetAggregationQuery, GetAggregationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAggregationQuery, GetAggregationQueryVariables>(GetAggregationDocument, options);
      }
export function useGetAggregationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAggregationQuery, GetAggregationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAggregationQuery, GetAggregationQueryVariables>(GetAggregationDocument, options);
        }
export function useGetAggregationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAggregationQuery, GetAggregationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAggregationQuery, GetAggregationQueryVariables>(GetAggregationDocument, options);
        }
export type GetAggregationQueryHookResult = ReturnType<typeof useGetAggregationQuery>;
export type GetAggregationLazyQueryHookResult = ReturnType<typeof useGetAggregationLazyQuery>;
export type GetAggregationSuspenseQueryHookResult = ReturnType<typeof useGetAggregationSuspenseQuery>;
export type GetAggregationQueryResult = Apollo.QueryResult<GetAggregationQuery, GetAggregationQueryVariables>;
export const GetCampaignsDocument = gql`
    query getCampaigns {
  getCampaigns
}
    `;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
      }
export function useGetCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export function useGetCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsSuspenseQueryHookResult = ReturnType<typeof useGetCampaignsSuspenseQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<GetCampaignsQuery, GetCampaignsQueryVariables>;
export const GetPayoutsDocument = gql`
    query getPayouts {
  getPayouts
}
    `;

/**
 * __useGetPayoutsQuery__
 *
 * To run a query within a React component, call `useGetPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayoutsQuery(baseOptions?: Apollo.QueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
      }
export function useGetPayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
        }
export function useGetPayoutsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
        }
export type GetPayoutsQueryHookResult = ReturnType<typeof useGetPayoutsQuery>;
export type GetPayoutsLazyQueryHookResult = ReturnType<typeof useGetPayoutsLazyQuery>;
export type GetPayoutsSuspenseQueryHookResult = ReturnType<typeof useGetPayoutsSuspenseQuery>;
export type GetPayoutsQueryResult = Apollo.QueryResult<GetPayoutsQuery, GetPayoutsQueryVariables>;