import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorPage from "components/ErrorPage/ErrorPage";
import StripePaywallModal from "components/StripePaywall/StripePaywallModal";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AUTH_PATH } from "views/auth/AuthPath";
import AuthRoutes from "views/auth/AuthRoutes";
import { CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import CampaignsRoutes from "views/campaigns/CampaignsRoutes";
import Dashboard from "views/dashboard/Dashboard";
import { ORGANISATION_PATH } from "views/organisation/OrganisationPath";
import OrganisationRoutes from "views/organisation/OrganisationRoutes";
import { PARTNERS_PATH } from "views/partners/PartnersPath";
import PartnersRoutes from "views/partners/PartnersRoutes";
import { PAYOUTS_PATH } from "views/payouts/PayoutPath";
import PayoutRoutes from "views/payouts/PayoutRoutes";
import { SETTINGS_PATH } from "views/settings/SettingsPath";
import SettingsRoutes from "views/settings/SettingsRoutes";
import { USER_PATH } from "views/user/UserPath";
import UserRoutes from "views/user/UserRoutes";
import PrivateRoute from "./PrivateRoute";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={AUTH_PATH + "*"} element={<AuthRoutes />} />
      <Route
        element={
          <ErrorBoundary>
            <PrivateRoute />
          </ErrorBoundary>
        }
      >
        <Route path={CAMPAIGNS_PATH + "*"} element={<CampaignsRoutes />} />
        <Route path={PAYOUTS_PATH + "*"} element={<PayoutRoutes />} />
        <Route path={PARTNERS_PATH + "*"} element={<PartnersRoutes />} />
        <Route path={USER_PATH + "*"} element={<UserRoutes />} />
        <Route path={ORGANISATION_PATH + "*"} element={<OrganisationRoutes />} />
        <Route path={SETTINGS_PATH + "*"} element={<SettingsRoutes />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <ErrorBoundary>
              <Dashboard />
              <StripePaywallModal />
            </ErrorBoundary>
          }
        />
      </Route>
      {/* Routes for Error pages */}
      <Route path="/403" element={<ErrorPage statusCode={403} />} />
      <Route path="/404" element={<ErrorPage statusCode={404} />} />
      <Route path="/500" element={<ErrorPage statusCode={500} />} />
      <Route path="*" element={<ErrorPage statusCode={404} />} />
    </Routes>
  );
};

export default AppRoutes;
