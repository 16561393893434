import { ReactComponent as EditIcon } from "assets/tailwind/icons/pen-line.svg";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetLink from "components/tailwind/MonetLink";
import StatusBadge from "components/tailwind/StatusBadge";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { getPartnerInitials } from "utils/Partner";
import OrganisationLogoDrawer from "../OrganisationLogoDrawer";
import ProfileDetailsSection from "../ProfileDetailsSection";

const OrganisationDetails = () => {
  const { partner } = useSessionProvider();
  const { openModal } = useModal();

  return (
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-10 gap-6">
        <div className="flex flex-col gap-6 text-sm col-span-10 md:col-span-3">
          <MonetCard className="flex flex-col h-[300px]">
            <MonetButton size="x-small" className="self-end" onClick={() => openModal(OrganisationLogoDrawer, {})}>
              <EditIcon />
              Edit
            </MonetButton>
            <div className="flex flex-col flex-1 gap-3 justify-center items-center">
              {partner?.defaultConfiguration?.logoUrl ? (
                <img src={partner?.defaultConfiguration?.logoUrl} style={{ maxWidth: 100, maxHeight: 100 }} alt="partner-logo" />
              ) : (
                <div className="p-4 rounded-full w-[100px] h-[100px] bg-gray-200 flex justify-center items-center text-3xl font-light">{getPartnerInitials(partner!.name)}</div>
              )}
              <StatusBadge type="partner" text={partner?.kybStatus} />
              ID: {partner?.partnerId}
            </div>
          </MonetCard>
          {partner?.businessMetadata?.companyWebsite && (
            <MonetCard className="items-center text-monet-blue">
              <MonetLink href={partner?.businessMetadata?.companyWebsite} showIcon={true}>
                {partner?.businessMetadata?.companyWebsite}
              </MonetLink>
            </MonetCard>
          )}
        </div>
        <ProfileDetailsSection />
      </div>
    </div>
  );
};

export default OrganisationDetails;
