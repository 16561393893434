import { formatStorageType, Money, MoneyStorage } from "@monet-money/money-type";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import StatusBadge from "components/tailwind/StatusBadge";
import { useSessionProvider } from "contexts/SessionProvider";
import React, { useCallback, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Campaign } from "shared/types/eep.contract.types";
import { invoiceFundingStatuses } from "shared/utils/invoice";
import { DATETIME_STRING_FORMAT, formatDate } from "utils/date";
import { Invoice } from "utils/Invoice";
import { isInAdminContext } from "utils/User";
import { CAMPAIGNS_DETAILS_PATH, CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import { campaignStatuses } from "views/campaigns/utils";

type CampaignsTableProps = {
  campaigns: Campaign[];
  invoices?: Invoice[];
  isLoading?: boolean;
  actionItems?: Array<{
    key: string;
    element: React.ReactNode;
    isPrimaryAction?: boolean;
    visible?: boolean;
    hideInMobileMenu?: boolean;
  }>;
};

const CampaignsTable: React.FC<CampaignsTableProps> = ({ isLoading, campaigns, actionItems, invoices }) => {
  const { user } = useSessionProvider();
  const navigate = useNavigate();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    partnerId: isInAdminContext(user),
  });

  const getCampaignFundingStatus = useCallback(
    (campaignId: string) => {
      const overdueInvoice = invoices?.find((invoice) => invoice.campaignId === campaignId && invoice.invoiceStatus === "OVERDUE");
      if (overdueInvoice) return "OVERDUE";

      const rejectedInvoice = invoices?.find((invoice) => invoice.campaignId === campaignId && invoice.funding?.fundingStatus === "REJECTED");
      if (rejectedInvoice) return "REJECTED";

      const activeInvoice = invoices?.find((invoice) => invoice.campaignId === campaignId && invoice.funding?.fundingStatus === "ACTIVE");
      if (activeInvoice) return "ACTIVE";

      const submittedInvoice = invoices?.find((invoice) => invoice.campaignId === campaignId && invoice.funding?.fundingStatus === "SUBMITTED");
      if (submittedInvoice) return "SUBMITTED";

      const settledInvoice = invoices?.find((invoice) => invoice.campaignId === campaignId && invoice.funding?.fundingStatus === "SETTLED");
      if (settledInvoice) return "SETTLED";

      return null;
    },
    [invoices],
  );

  const columns: GridColDef<Campaign, any>[] = [
    { field: "partnerId", headerName: "Partner ID", minWidth: 150 },
    {
      field: "campaignStatus",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: campaignStatuses,
      minWidth: 125,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.campaignStatus;
      },
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        return <StatusBadge type="campaign" text={params.row.campaignStatus} />;
      },
    },
    {
      field: "fundingStatus",
      headerName: "Funding status",
      type: "singleSelect",
      valueOptions: [...invoiceFundingStatuses, "OVERDUE"],
      minWidth: 125,
      valueGetter: (params: GridValueGetterParams) => {
        return getCampaignFundingStatus(params.row.campaignId);
      },
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        const status = getCampaignFundingStatus(params.row.campaignId);
        return status ? <StatusBadge type="invoice" text={status} /> : "-";
      },
    },
    {
      field: "campaignId",
      headerName: "Campaign ID",
      width: 250,
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        const campaign = params.row as Campaign;
        return campaign.campaignId;
      },
    },
    { field: "campaignName", headerName: "Campaign name", minWidth: 300 },
    {
      field: "client",
      headerName: "Client",
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams<Campaign>) => {
        return params.row.client.legalName;
      },
    },
    {
      field: "campaignValue",
      headerName: "Campaign amount",
      type: "number",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return Number(Money.fromStorageType(params.value).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        return formatStorageType(params.row.campaignValue as MoneyStorage);
      },
    },
    {
      field: "budgetLimit",
      headerName: "Budget limit",
      type: "number",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return Number(Money.fromStorageType(params.value).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        return formatStorageType(params.row.budgetLimit as MoneyStorage);
      },
    },
    {
      field: "allocatedFunds",
      headerName: "Funding used",
      type: "number",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return Number(Money.fromStorageType(params.value).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        return formatStorageType(params.row.allocatedFunds as MoneyStorage);
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      type: "dateTime",
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value),
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        return formatDate(params.row.createdAt, DATETIME_STRING_FORMAT);
      },
    },
  ];

  const handleRowClick = (params: GridRowParams) => {
    navigate(
      generatePath(CAMPAIGNS_PATH + CAMPAIGNS_DETAILS_PATH, {
        campaignId: encodeURIComponent(params.row.campaignId),
        partnerId: encodeURIComponent(params.row.partnerId),
      }),
    );
  };

  return (
    <CustomDataGrid
      rows={campaigns}
      onRowClick={handleRowClick}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "createdAt",
              sort: "desc",
            },
          ],
        },
      }}
      toolbarActionItems={actionItems}
      isDataLoading={isLoading}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
      getRowId={(row: Campaign) => row.campaignId}
      sx={{ minHeight: 500 }}
    />
  );
};

export default CampaignsTable;
