import { setUpTOTP, verifyTOTPSetup } from "aws-amplify/auth";
import MonetButton from "components/tailwind/MonetButton";
import MonetPinInput from "components/tailwind/form/MonetPinInput";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { HSPinInput } from "preline/preline";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

enum CurrentView {
  QRView,
  VerifyView,
}

const AddAuthenticatorAppDrawer: React.FC = () => {
  const [qrCodeLink, setQrCodeLink] = useState<string>();
  const [secretKey, setSecretKey] = useState<string | null>();
  const [currentView, setCurrentView] = useState<CurrentView>(CurrentView.QRView);
  const [viewSecretKey, setViewSecretKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSessionProvider();
  const { closeModal } = useModal();

  const resetComponent = () => {
    setQrCodeLink(undefined);
    setSecretKey(undefined);
    setCurrentView(CurrentView.QRView);
    setViewSecretKey(false);
    setIsLoading(false);
    closeModal();
  };

  useEffect(() => {
    setUpUserTOTP();
  }, []);

  const setUpUserTOTP = async () => {
    try {
      setIsLoading(true);
      const totpSetupDetails = await setUpTOTP();
      const env = process.env.REACT_APP_ENV_NAME;
      const appName = env === "monetprod" ? "Monet EarlyPay" : `Monet EarlyPay ${env}`;
      const setupUri = totpSetupDetails.getSetupUri(appName, user.email);

      setQrCodeLink(setupUri.href);
      const params = new URLSearchParams(setupUri.search);
      setSecretKey(params.get("secret"));
    } catch (error: any) {
      toast.error("Error generating QR code", { description: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const verifyTOTP = async () => {
    const el = HSPinInput.getInstance("#pin-input") as any;
    const totpCode = (el.currentValue as Array<string>).join("");

    try {
      setIsLoading(true);
      await verifyTOTPSetup({ code: totpCode });
      toast.success("Success", { description: "Authenticator app set up. You can now select this as your preferred method" });
      resetComponent();
    } catch (error: any) {
      toast.error("Error verifying code", { description: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextClick = () => {
    if (currentView === CurrentView.QRView) {
      setCurrentView(currentView + 1);
    } else if (currentView === CurrentView.VerifyView) {
      verifyTOTP();
    }
  };

  return (
    <TailwindDrawer title="Set up a authenticator app" subtitle="Set up your preferred authenticator app such as, Microsoft Authenticator" onCloseCallback={resetComponent}>
      {currentView === CurrentView.QRView ? (
        <div className="flex flex-col flex-1 justify-center items-center gap-6" data-testid="authenticator-app-qr-code-container">
          <p className="text-sm text-center">
            Scan the QR code below in your authenticator app of choice, if you have trouble scanning the QR code use the secret key. Once you have done that move onto the next step
          </p>
          {qrCodeLink && <QRCodeSVG size={200} value={qrCodeLink} />}
          <div className="flex flex-col">
            <p className="text-sm">If you are having trouble scanning the code</p>
            <MonetButton variant="link" size="fit-content" onClick={() => setViewSecretKey(!viewSecretKey)}>
              View secret key
            </MonetButton>
          </div>
          {viewSecretKey && secretKey && (
            <p className="text-center">
              {/* Split the key into multiple lines to avoid overflowing it's parent container */}
              {secretKey.match(/.{1,30}/g)!.map((x) => (
                <p key={x}>{x}</p>
              ))}
            </p>
          )}
        </div>
      ) : (
        <div className="flex flex-col flex-1 justify-center gap-5">
          <p className="text-sm">Enter the 6 digit code that is displayed in your authenticator app below</p>
          <MonetPinInput />
        </div>
      )}
      <div className="flex flex-row justify-between gap-4">
        <MonetButton onClick={resetComponent} variant="outlined" color="gray-outlined" className="flex-1" disabled={isLoading}>
          Cancel
        </MonetButton>
        <MonetButton type="submit" className="flex-1" onClick={handleNextClick} disabled={isLoading}>
          {currentView === CurrentView.VerifyView ? "Verify" : "Next"}
        </MonetButton>
      </div>
    </TailwindDrawer>
  );
};

export default AddAuthenticatorAppDrawer;
