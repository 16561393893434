import { formatStorageType, Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { Typography } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import { CountryNameAndFlag } from "components/CountryFlag/CountryFlag";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CreatePayoutButton from "components/tailwind/CreatePayoutButton";
import MonetDropdownMenuItem from "components/tailwind/MonetDropdownMenuItem";
import StatusBadge from "components/tailwind/StatusBadge";
import { useSessionProvider } from "contexts/SessionProvider";
import { getName } from "i18n-iso-countries";
import React, { useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getPayoutTotalDetails } from "shared/logic/payouts";
import { Campaign, FullPayout } from "shared/types/eep.contract.types";
import { convertUnixTimestampToDate, DATETIME_STRING_FORMAT, formatDate } from "utils/date";
import { restrictPlatformAccess } from "utils/Partner";
import { getCampaignField, getDaysFunded, PAYOUT_TYPES_LIST, payoutStatuses } from "utils/Payouts";
import getCurrencySymbol from "utils/strings";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { PAYOUT_CREATE, PAYOUT_DETAILS, PAYOUT_VENDOR_PATH, PAYOUT_WITHDRAWAL_PATH, PAYOUTS_PATH } from "views/payouts/PayoutPath";

type PayoutsTableProps = {
  payouts: FullPayout[];
  campaigns: Campaign[];
  showCampaignName?: boolean;
  isLoading?: boolean;
  enableExport?: boolean;
  autoPageSize?: boolean;
};

const PayoutsTable: React.FC<PayoutsTableProps> = ({ payouts, showCampaignName = false, enableExport, autoPageSize, campaigns, isLoading }) => {
  const { user, partner } = useSessionProvider();
  const navigate = useNavigate();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    campaignName: showCampaignName,
    partnerId: isInAdminContext(user),
  });

  const payoutsBankCountryCodes = useMemo(() => {
    return [...new Set(payouts.map((payout) => getName(payout.payee.country, "en") || ""))];
  }, [payouts]);

  const payoutsBeneficiaryCurrencies = useMemo(() => {
    return [...new Set(payouts.map((payout) => payout.payee.country ?? ""))];
  }, [payouts]);

  const noPayoutsView = () => (
    <Typography height="100%" textAlign="center">
      No payouts
    </Typography>
  );

  const columns: GridColDef[] = [
    { field: "partnerId", headerName: "Partner ID", minWidth: 250, flex: 1 },
    {
      field: "payoutStatus",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: payoutStatuses,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return <StatusBadge type="payout" text={params.row.payoutStatus} />;
      },
    },
    { field: "payee.email", headerName: "Payee email", minWidth: 260, flex: 2, valueGetter: (params: GridValueGetterParams) => params.row.payee.email },
    {
      field: "campaignName",
      headerName: "Campaign name",
      minWidth: 200,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => getCampaignField("campaignName", params.row.campaignId, campaigns),
    },
    {
      field: "invoiceId",
      headerName: "Invoice Id",
      minWidth: 200,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => params.row.invoiceId ?? "",
    },
    {
      field: "payoutRef",
      headerName: "Payout reference",
      minWidth: 200,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => params.row.payoutRef ?? "",
    },
    {
      field: "payee.bankCountryCode",
      headerName: "Payee country",
      minWidth: 200,
      type: "singleSelect",
      valueOptions: payoutsBankCountryCodes,
      valueGetter: (params: GridValueGetterParams) => getName(params.row.payee.bankCountryCode, "en"),
      renderCell: (params: GridRenderCellParams<FullPayout>) => <CountryNameAndFlag width={16} countryCode={params.row.payee.country} />,
    },
    {
      field: "payee.currency",
      headerName: "Payee currency",
      minWidth: 150,
      type: "singleSelect",
      valueOptions: payoutsBeneficiaryCurrencies,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => params.row.payee.currency,
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        const currency = params.row.payee.currency;
        if (!currency) return "";
        return `${currency} (${getCurrencySymbol(currency)})`;
      },
    },
    {
      field: "payoutValue",
      headerName: "Payout value",
      type: "number",
      width: 120,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => {
        return Number(Money.fromStorageType(params.row.payoutValue as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return formatStorageType(params.row.payoutValue as MoneyStorage);
      },
    },
    {
      field: "feeSettings.earlyPayTotal",
      headerName: "EarlyPay total",
      type: "number",
      width: 120,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => {
        return Number(Money.fromStorageType(params.row.feeSettings.earlyPayTotal as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return formatStorageType(params.row.feeSettings.earlyPayTotal as MoneyStorage);
      },
    },
    {
      field: "feeSettings.feeTotal",
      headerName: "Creator fee",
      type: "number",
      width: 120,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => {
        return Number(Money.fromStorageType(params.row.feeSettings.feeTotal as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return formatStorageType(params.row.feeSettings.feeTotal as MoneyStorage);
      },
    },
    {
      field: "feeSettings.monetFee",
      headerName: "MONET earnings",
      type: "number",
      width: 120,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => {
        const monetFeeMoney = getPayoutTotalDetails(
          params.row.payoutValue as MoneyStorage,
          Percentage.fromStorageValue(params.row.feeSettings.monetFee),
          Percentage.fromStorageValue(params.row.feeSettings.partnerFee),
          params.row.feeSettings.chargeCreator,
        ).monetFee;

        return Number(monetFeeMoney.format(false, false));
      },
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return `${getCurrencySymbol(params.row.payoutValue.currency)}${params.value}`;
      },
    },
    {
      field: "feeSettings.partnerFee",
      headerName: "Partner earnings",
      type: "number",
      width: 120,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => {
        const partnerFeeMoney = getPayoutTotalDetails(
          params.row.payoutValue as MoneyStorage,
          Percentage.fromStorageValue(params.row.feeSettings.monetFee),
          Percentage.fromStorageValue(params.row.feeSettings.partnerFee),
          params.row.feeSettings.chargeCreator,
        ).partnerFee;

        return Number(partnerFeeMoney.format(false, false));
      },
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return `${getCurrencySymbol(params.row.payoutValue.currency)}${params.value}`;
      },
    },
    {
      field: "daysFunded",
      headerName: "Days funded",
      type: "number",
      width: 120,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => params.row.feeSettings.daysFunded ?? null,
      valueFormatter: (params: GridValueFormatterParams) => getDaysFunded(params.value),
    },
    {
      field: "ttl",
      headerName: "Expires on",
      minWidth: 250,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams<FullPayout>) => convertUnixTimestampToDate(params.row.ttl),
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        if (params.value) {
          return formatDate(params.row.ttl, DATETIME_STRING_FORMAT);
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "payoutType",
      headerName: "Payout Type",
      type: "singleSelect",
      minWidth: 150,
      valueOptions: PAYOUT_TYPES_LIST,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      type: "dateTime",
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value),
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return formatDate(params.row.createdAt, DATETIME_STRING_FORMAT);
      },
    },
  ];

  return (
    <CustomDataGrid
      isDataLoading={isLoading}
      rows={payouts}
      columns={columns}
      autoPageSize={autoPageSize}
      enableExport={enableExport}
      toolbarActionItems={[
        {
          key: "create-payout",
          visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
          isPrimaryAction: true,
          element: <CreatePayoutButton />,
          hideInMobileMenu: true,
        },
        {
          key: "withdraw-funds",
          visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
          element: (
            <MonetDropdownMenuItem className="block sm:hidden" onClick={() => navigate(PAYOUTS_PATH + PAYOUT_CREATE + PAYOUT_WITHDRAWAL_PATH)} subtext="Get invoices paid early">
              Withdraw funds
            </MonetDropdownMenuItem>
          ),
        },
        {
          key: "vendor-payout",
          visible: !restrictPlatformAccess(partner, user) && isInPartnerContext(user),
          element: (
            <MonetDropdownMenuItem className="block sm:hidden" onClick={() => navigate(PAYOUTS_PATH + PAYOUT_CREATE + PAYOUT_VENDOR_PATH)} subtext="Pay suppliers/talent early">
              Pay vendors
            </MonetDropdownMenuItem>
          ),
        },
      ]}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "createdAt",
              sort: "desc",
            },
          ],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onRowClick={(params) => {
        navigate(
          `${PAYOUTS_PATH}${PAYOUT_DETAILS}?` +
            createSearchParams({
              payoutId: params.row.payoutId as string,
            }).toString(),
        );
      }}
      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
      components={{
        NoRowsOverlay: noPayoutsView,
      }}
      getRowId={(row: FullPayout) => row.payoutId}
      sx={{ minHeight: 500 }}
    />
  );
};

export default PayoutsTable;
