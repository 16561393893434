import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import StatusBadge from "components/tailwind/StatusBadge";
import dayjs from "dayjs";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Campaign, FullPayout } from "shared/types/eep.contract.types";
import { getCampaignField, payoutStatuses } from "utils/Payouts";
import { DEFAULT_DATE_FORMAT } from "utils/date";
import { PAYOUTS_PATH, PAYOUT_DETAILS } from "views/payouts/PayoutPath";

type PayoutsTableProps = {
  payouts: FullPayout[];
  campaigns: Campaign[];
  isPayoutsLoading: boolean;
  actionItems?: Array<{
    key: string;
    element: React.ReactNode;
    isPrimaryAction?: boolean;
    visible?: boolean;
    hideInMobileMenu?: boolean;
  }>;
};
const PayoutsTable: React.FC<PayoutsTableProps> = ({ payouts, campaigns, isPayoutsLoading, actionItems }) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "payoutStatus",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueOptions: payoutStatuses,
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return <StatusBadge type="payout" text={params.row.payoutStatus} />;
      },
    },
    {
      field: "campaignName",
      headerName: "Campaign name",
      flex: 1,
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => getCampaignField("campaignName", params.row.campaignId, campaigns),
    },
    { field: "payee.email", headerName: "Email", minWidth: 300, valueGetter: (params: GridValueGetterParams) => params.row.payee.email },
    {
      field: "payoutValue",
      headerName: "Payout value",
      type: "number",
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams<FullPayout>) => {
        return Number(Money.fromStorageType(params.row.payoutValue as MoneyStorage).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<FullPayout>) => {
        return formatStorageType(params.row.payoutValue as MoneyStorage);
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      type: "date",
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => (params.value ? dayjs(params.value, DEFAULT_DATE_FORMAT).toDate() : undefined),
      valueFormatter: (params: GridValueFormatterParams) => (params.value ? dayjs(params.value).format(DEFAULT_DATE_FORMAT) : "N/A"),
    },
  ];

  const handleRowClick = (params: any) => {
    const path = `${PAYOUTS_PATH}${PAYOUT_DETAILS}?${createSearchParams({
      payoutId: params.row.payoutId as string,
    }).toString()}`;

    navigate(path);
  };

  return (
    <CustomDataGrid
      enableExport={false}
      hasFooter={false}
      rows={payouts}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "createdAt",
              sort: "desc",
            },
          ],
        },
      }}
      title="Latest payouts"
      autoPageSize
      hideFooterPagination
      isDataLoading={isPayoutsLoading}
      toolbarActionItems={actionItems}
      onRowClick={handleRowClick}
      getRowId={(row: FullPayout) => row.payoutId}
      sx={{
        height: 400,
      }}
    />
  );
};

export default PayoutsTable;
