import { ReactComponent as EditIcon } from "assets/tailwind/icons/pen-line.svg";
import MonetButton from "./MonetButton";

type MonetEditSaveButtonProps = {
  showSaveButton: boolean;
  onEditCallBack(): void;
  cancelButtonDisabled?: boolean;
  saveButtonDisabled?: boolean;
  loading?: boolean;
  onSaveCallback?(): boolean;
  onCancelCallback(): void;
};

const MonetEditSaveButton: React.FC<MonetEditSaveButtonProps> = ({
  showSaveButton,
  onCancelCallback,
  onSaveCallback,
  onEditCallBack,
  cancelButtonDisabled,
  saveButtonDisabled,
  loading,
}) => {
  return (
    <>
      {!showSaveButton && (
        <MonetButton size="x-small" onClick={onEditCallBack}>
          <EditIcon />
          Edit
        </MonetButton>
      )}
      {showSaveButton && (
        <div className="w-[150px] flex gap-2">
          <MonetButton size="x-small" className="flex-1" variant="outlined" color="gray-outlined" disabled={cancelButtonDisabled || loading} onClick={onCancelCallback}>
            Cancel
          </MonetButton>
          <MonetButton type="submit" size="x-small" color="green-solid" className="flex-1" disabled={saveButtonDisabled || loading} loading={loading}>
            Save
          </MonetButton>
        </div>
      )}
    </>
  );
};

export default MonetEditSaveButton;
