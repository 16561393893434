import { Currency, Money, MoneyStorage } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import dayjs from "dayjs";
import { ConfigurationFeeSettings, FeeSettings, FullPayout } from "types/eep.contract.types";
import { getPayoutTotals } from "./payouts";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_NUMERIC_DATE = "YYYYMMDD";

// PARTNER_NOT_FOUND included as a possible response but is not possible via api. User needs to exist for auth.
export type ErrorMessages = Components.Schemas.Error["message"] | "TOKEN_EXPIRED" | "TOKEN_INVALID" | "PARTNER_NOT_FOUND";

// Overwriting Components.Schemas.Error because of additional error messages
export type ErrorMessage = {
  fieldName?: string;
  message?: ErrorMessages;
};

export const calculateFees = (
  invoiceTotal: MoneyStorage,
  requestFee: number | undefined,
  feeSettings: ConfigurationFeeSettings,
  daysRemaining: number,
  payoutType: Components.Schemas.PayoutType = "VENDOR",
): FeeSettings | ErrorMessage => {
  let resolvedFee = requestFee === undefined ? Percentage.fromStorageValue(feeSettings.default) : new Percentage(requestFee, undefined, true);

  const dailyMonetFee = Percentage.fromStorageValue(feeSettings.monet);
  let monetFee = dailyMonetFee.multiply(daysRemaining);

  if (payoutType == "WITHDRAWAL") {
    resolvedFee = monetFee;
  }

  // Validate fee
  if (resolvedFee) {
    // MIN FEE CHECK - Fee from the request can not be less than 0 if it exists
    if (resolvedFee.percentage < 0) {
      return { message: "FEE_BELOW_ZERO", fieldName: "fee" };
    }
  }

  //Set chargeCreator flag
  const chargeCreator = resolvedFee.percentage == 0 ? false : true;

  const invoiceTotalAmount = Money.fromStorageType(invoiceTotal);
  let feeTotal = Money.fromStorageType(invoiceTotal).multiply(resolvedFee.percentage);
  let earlyPayTotal = Money.fromStorageType(invoiceTotal).subtract(feeTotal);

  //calculate fees

  let calculatedPartnerFee = resolvedFee.subtract(monetFee);

  const monetTotalFee = Money.fromStorageType(invoiceTotal).multiply(monetFee.percentage);
  if (monetTotalFee.amount < 1) {
    const newMonetFeePercentage = (0.01 / invoiceTotal.amount) * 100;
    let newResolvedFee = resolvedFee;
    const oldMonetFee = monetFee;
    monetFee = new Percentage(newMonetFeePercentage, undefined, true);
    // Check if monet fee equals resolved fee
    if (resolvedFee.percentage == oldMonetFee.percentage) {
      // Set resolved fee to new monet fee
      newResolvedFee = monetFee;
    }
    // Getting partner fee from new resolved fee
    calculatedPartnerFee = newResolvedFee.subtract(monetFee);
    const newFee = calculatedPartnerFee.add(monetFee);
    feeTotal = invoiceTotalAmount.multiply(newFee.percentage);
    earlyPayTotal = Money.fromStorageType(invoiceTotal).subtract(feeTotal);
  }

  // MAX FEE CHECK
  if (calculatedPartnerFee.percentage > feeSettings.max || (requestFee !== undefined && requestFee > 1)) {
    return { message: "FEE_ABOVE_MAXIMUM", fieldName: "fee" };
  }

  if (!chargeCreator) {
    earlyPayTotal = Money.fromStorageType(invoiceTotal);
  }

  if (earlyPayTotal.amount < 0) {
    return { message: "TOTAL_BELOW_ZERO", fieldName: "invoice.invoiceTotal.amount" };
  }

  return {
    chargeCreator,
    daysFunded: daysRemaining,
    monetFee: monetFee.percentage,
    partnerFee: calculatedPartnerFee.percentage,
    feeTotal: Money.toStorageType(feeTotal),
    earlyPayTotal: Money.toStorageType(earlyPayTotal),
    fee: resolvedFee.percentage,
  };
};

export const calculateDFF = (margin: number, baseRate: number) => {
  const marginPercentage = Percentage.fromStorageValue(margin);
  const baseRatePercentage = Percentage.fromStorageValue(baseRate);

  const totalFunding = marginPercentage.add(baseRatePercentage).multiply(100);

  return new Percentage(totalFunding.percentage / 365, 5);
};

export const calculateReconcileDaysElapsed = (invoiceDueDate: string, reconciliationDate: string) => {
  const dueDateFormatted = dayjs(invoiceDueDate, DEFAULT_NUMERIC_DATE);
  const reconDate = dayjs(reconciliationDate, DEFAULT_NUMERIC_DATE);
  const daysElapsed = Math.ceil(reconDate.diff(dueDateFormatted, "days", true));
  return daysElapsed > 0 ? daysElapsed : 0;
};

export const calculateReconciliationFields = (invoiceValue: MoneyStorage, invoiceId: string, payouts: FullPayout[], daysElapsed: number, monetDailyFee?: Percentage) => {
  const currency = invoiceValue.currency as Currency;
  let principal = new Money(0, currency);
  let earlyPayFees = new Money(0, currency);
  let partnerFee = new Money(0, currency);

  const filteredPayouts = payouts.filter((x) => x.payoutStatus === "BENEFICIARY_PAID" && x.invoiceId === invoiceId);

  for (const payout of filteredPayouts) {
    principal = principal.add(Money.fromStorageType(payout.feeSettings.earlyPayTotal));

    const calculatedFees = getPayoutTotals(payout as unknown as Components.Schemas.Payout);
    earlyPayFees = earlyPayFees.add(calculatedFees.monetFee);

    partnerFee = partnerFee.add(calculatedFees.partnerFee);
  }

  let earlyPayLateFees = new Money(0, currency);
  let dailyLateFee = new Money(0, currency);
  if (monetDailyFee) {
    dailyLateFee = principal.multiply(monetDailyFee.percentage);
    earlyPayLateFees = dailyLateFee.multiply(daysElapsed);
  }

  const totalEarlyPayFees = earlyPayFees.add(earlyPayLateFees);

  const campaignValue = Money.fromStorageType(invoiceValue);

  const residual = campaignValue.subtract(principal).subtract(earlyPayFees).subtract(earlyPayLateFees).subtract(partnerFee);

  return {
    principal,
    earlyPayFees,
    partnerFee,
    earlyPayLateFees,
    totalEarlyPayFees,
    residual,
    allChecksToZero: residual,
    dailyLateFee,
  };
};
