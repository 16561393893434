import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import StatusBadge from "components/tailwind/StatusBadge";
import dayjs from "dayjs";
import { generatePath, useNavigate } from "react-router-dom";
import { Campaign } from "shared/types/eep.contract.types";
import { DEFAULT_DATE_FORMAT, DUE_DATE_FORMAT } from "utils/date";
import { CAMPAIGNS_DETAILS_PATH, CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import { campaignStatuses, getCampaignRemainingValue } from "views/campaigns/utils";

type CampaignsTableProps = {
  campaigns: Campaign[];
  isCampaignsLoading: boolean;
  actionItems?: Array<{
    key: string;
    element: React.ReactNode;
    isPrimaryAction?: boolean;
    visible?: boolean;
    hideInMobileMenu?: boolean;
  }>;
};

const CampaignsTable: React.FC<CampaignsTableProps> = ({ campaigns, isCampaignsLoading, actionItems }) => {
  const navigate = useNavigate();

  const columns: GridColDef<Campaign, any>[] = [
    {
      field: "campaignStatus",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      valueOptions: campaignStatuses,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        return <StatusBadge type="campaign" text={params.row.campaignStatus} />;
      },
    },

    { field: "campaignName", headerName: "Campaign name", flex: 1, minWidth: 300 },
    {
      field: "client.legalName",
      headerName: "Client",
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.client.legalName,
    },
    {
      field: "dueDate",
      headerName: "Due date",
      flex: 1,
      type: "date",
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => (params.value ? dayjs(params.value, DUE_DATE_FORMAT).toDate() : undefined),
      valueFormatter: (params: GridValueFormatterParams) => (params.value ? dayjs(params.value).format(DEFAULT_DATE_FORMAT) : "N/A"),
    },
    {
      field: "budgetLimit",
      headerName: "Budget limit",
      type: "number",
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return Number(Money.fromStorageType(params.value).format(false, false));
      },
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        return formatStorageType(params.row.budgetLimit as MoneyStorage);
      },
    },
    {
      field: "campaign",
      headerName: "Remaining funds",
      type: "number",
      flex: 1,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return Number(getCampaignRemainingValue(params.row, false));
      },
      renderCell: (params: GridRenderCellParams) => {
        return getCampaignRemainingValue(params.row, true);
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      type: "date",
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams) => (params.value ? dayjs(params.value, DEFAULT_DATE_FORMAT).toDate() : undefined),
      valueFormatter: (params: GridValueFormatterParams) => (params.value ? dayjs(params.value).format(DEFAULT_DATE_FORMAT) : "N/A"),
    },
  ];

  const handleRowClick = (params: any) => {
    const path = generatePath(CAMPAIGNS_PATH + CAMPAIGNS_DETAILS_PATH, {
      campaignId: encodeURIComponent(params.row.campaignId),
      partnerId: encodeURIComponent(params.row.partnerId),
    });

    navigate(path);
  };

  return (
    <CustomDataGrid
      title="Latest campaigns"
      enableExport={false}
      hasFooter={false}
      rows={campaigns}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "createdAt",
              sort: "desc",
            },
          ],
        },
      }}
      autoPageSize
      hideFooterPagination
      autoHeight={false}
      toolbarActionItems={actionItems}
      isDataLoading={isCampaignsLoading}
      onRowClick={handleRowClick}
      getRowId={(row: Campaign) => row.campaignId!}
      sx={{
        height: 400,
      }}
    />
  );
};

export default CampaignsTable;
