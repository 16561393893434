import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useModal } from "contexts/ModalProvider";
import { PropsWithChildren, useCallback } from "react";

type TailwindDrawerProps = PropsWithChildren & {
  title: string;
  subtitle?: string;
  onCloseCallback?(): void;
  isOpen?: boolean;
};

const TailwindDrawer: React.FC<TailwindDrawerProps> = ({ children, title, subtitle, onCloseCallback }) => {
  const { closeModal } = useModal();

  const handleClose = useCallback(() => {
    closeModal();
    onCloseCallback && onCloseCallback();
  }, []);

  return (
    <Dialog open={true} onClose={handleClose} className="relative z-10">
      <DialogBackdrop transition className="fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-300 ease-in-out data-[closed]:opacity-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-1 w-full max-w-xs sm:max-w-[450px]">
            <DialogPanel transition className="pointer-events-auto w-full relative transform transition ease-in-out data-[closed]:translate-x-full duration-300 sm:duration-300">
              <div className="flex flex-col p-4 sm:py-6 h-full justify-between overflow-y-auto bg-white border-s dark:bg-neutral-800 dark:border-neutral-700">
                <div className="flex flex-col flex-1 gap-4">
                  <div>
                    <h3 className="font-normal text-2xl text-gray-800 dark:text-white">{title}</h3>
                    <p className="text-gray-800 text-sm font-normal dark:text-neutral-400">{subtitle}</p>
                  </div>
                  <div className="flex flex-col flex-1">{children}</div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TailwindDrawer;
