import { yupResolver } from "@hookform/resolvers/yup";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetPhoneNumberInput from "components/tailwind/form/MonetPhoneNumberInput";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { toast } from "sonner";
import { isInPartnerContext } from "utils/User";
import { useCreateUserMutation } from "views/organisation/graphql/mutations.generated";
import { UserFormObject, userDefaultValues, userValidationSchema } from "../ValidationSchemas/addUserSchema";

type AddUserDrawerProps = {
  refetchUsers(): void;
};

const AddUserDrawer: React.FC<AddUserDrawerProps> = ({ refetchUsers }) => {
  const [createUser] = useCreateUserMutation();

  const { closeModal } = useModal();
  const { user, partner } = useSessionProvider();

  const onSubmit = async (formData: UserFormObject) => {
    try {
      await createUser({
        variables: {
          user: JSON.stringify({
            ...formData,
            partner: partner!.partnerId,
          }),
        },
      });
      resetComponent();
      toast.success("Success", { description: "User has been created. They will soon receive a email to set their password" });
      refetchUsers();
    } catch (error: any) {
      toast.error("Request failed", { description: "The user could not be created. " + error.message });
    }
  };

  const methods = useForm<UserFormObject>({
    mode: "onTouched",
    resolver: yupResolver(userValidationSchema) as Resolver<UserFormObject>,
    defaultValues: userDefaultValues,
  });

  const resetComponent = () => {
    closeModal();
    methods.reset();
  };

  return (
    <TailwindDrawer
      title="Add new user"
      subtitle="Fill out the below information to add a new user. The user will receive a email to set up their password for the first time"
      onCloseCallback={resetComponent}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 sm:flex-row">
              <MonetInput {...methods.register("firstName")} label="First name" id="firstName" error={methods.formState.errors.firstName?.message as string} />
              <MonetInput {...methods.register("lastName")} label="Last name" id="lastName" error={methods.formState.errors.lastName?.message as string} />
            </div>
            <MonetInput {...methods.register("email")} label="Email" id="email" autoComplete="username" error={methods.formState.errors.email?.message as string} />
            <MonetPhoneNumberInput label="Mobile number" name="phoneNumber" error={methods.formState.errors.phoneNumber?.message as string} />
            <MonetInput {...methods.register("role")} label="Role in company" id="role" error={methods.formState.errors.role?.message as string} />
          </div>
          <MonetDrawerButtons
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isValid}
            isPassive={!isInPartnerContext(user)}
            loading={methods.formState.isSubmitting}
            onCancelCallback={resetComponent}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default AddUserDrawer;
