import { yupResolver } from "@hookform/resolvers/yup";
import MonetCard from "components/tailwind/MonetCard";
import MonetCardTitle from "components/tailwind/MonetCardTitle";
import MonetEditSaveButton from "components/tailwind/MonetEditSaveButton";
import MonetInput from "components/tailwind/form/MonetInput";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useSessionProvider } from "contexts/SessionProvider";
import getSymbolFromCurrency from "currency-symbol-map";
import { useCallback, useMemo, useState } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { toast } from "sonner";
import { getTurnoversForInput, industries } from "utils/Partner";
import { getAllCountries } from "utils/country";
import { getCurrencies } from "utils/currency";
import { useUpdatePartnerBusinessMetadataMutation } from "views/organisation/graphql/mutations.generated";
import { OrganisationProfileDetailsFormObject, organisationProfileDetailsSchema } from "../ValidationSchemas/editOrganisationProfileSchema";

const ProfileDetailsSection = () => {
  const [editable, setEditable] = useState(false);
  const { partner, refetchCurrentPartner } = useSessionProvider();

  const [updateMetadata] = useUpdatePartnerBusinessMetadataMutation();

  const onSubmit = async (formData: OrganisationProfileDetailsFormObject) => {
    try {
      await updateMetadata({
        variables: {
          partnerBusinessMetadata: {
            ...formData,
            functionalCurrencies: formData.functionalCurrencies.filter((item) => item !== "-"),
          },
        },
      });
      toast.success("Success", { description: "Organisation profile has been updated" });
      location.reload();
    } catch (error) {
      toast.error("Request failed", { description: "Error updating organisation profile. Please try again" });
    } finally {
      toggleEdit();
    }
  };

  const methods = useForm<OrganisationProfileDetailsFormObject>({
    mode: "onTouched",
    resolver: yupResolver(organisationProfileDetailsSchema) as Resolver<OrganisationProfileDetailsFormObject>,
    defaultValues: {
      name: partner?.name,
      countryOfRegistration: partner?.businessMetadata?.countryOfRegistration,
      turnover: partner?.businessMetadata?.turnover,
      companyWebsite: partner?.businessMetadata?.companyWebsite,
      functionalCurrencies: partner?.businessMetadata?.functionalCurrencies,
      industry: partner?.businessMetadata?.industry,
      address: {
        addressLine1: partner?.address?.addressLine1,
        city: partner?.address?.city,
        postcode: partner?.address?.postcode,
      },
    },
  });

  const toggleEdit = useCallback(() => {
    if (editable) {
      methods.reset();
    }
    setEditable(!editable);
  }, [editable]);

  const country = methods.watch("countryOfRegistration");

  const turnoverOptions = useMemo(() => {
    if (country) {
      return getTurnoversForInput(country);
    } else return undefined;
  }, [country]);

  const transactionCurrencies = useMemo(() => {
    return getCurrencies.map((item) => ({
      label: `${item.currency} (${getSymbolFromCurrency(item.currency)})`,
      value: item.currency,
    }));
  }, []);

  const concatAddress = useMemo(() => {
    if (partner?.address) {
      const { addressLine1, addressLine2, city, postcode } = partner.address;
      return [addressLine1, addressLine2, city, postcode].filter(Boolean).join(", ");
    } else return "";
  }, [partner]);

  return (
    <MonetCard className="col-span-10 md:col-span-7 overflow-visible">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-row justify-between gap-4 mb-4">
            <MonetCardTitle>Profile</MonetCardTitle>
            <MonetEditSaveButton
              showSaveButton={editable}
              onEditCallBack={toggleEdit}
              onCancelCallback={toggleEdit}
              saveButtonDisabled={!methods.formState.isValid}
              loading={methods.formState.isSubmitting}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <MonetInput
              {...methods.register("name")}
              label="Company name"
              id="name"
              error={methods.formState.errors.name?.message as string}
              disabled={methods.formState.isSubmitting}
              readOnly={!editable}
            />
            <MonetInput
              {...methods.register("companyWebsite")}
              label="Company Website"
              id="companyWebsite"
              error={methods.formState.errors.companyWebsite?.message as string}
              disabled={methods.formState.isSubmitting}
              readOnly={!editable}
            />
            <Controller
              control={methods.control}
              name="countryOfRegistration"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Country of registration"
                  options={getAllCountries()}
                  placeholder="Please select..."
                  error={methods.formState.errors.countryOfRegistration?.message as string}
                  disabled={methods.formState.isSubmitting || !editable}
                />
              )}
            />
            <Controller
              control={methods.control}
              name="turnover"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Turnover"
                  placeholder="Please select..."
                  options={turnoverOptions || []}
                  error={methods.formState.errors.turnover?.message as string}
                  disabled={methods.formState.isSubmitting || !editable || !country}
                />
              )}
            />
            <Controller
              control={methods.control}
              name="functionalCurrencies"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  multiple={true}
                  label="Transaction currencies"
                  placeholder="Please select..."
                  tooltip={`The currencies that ${partner?.name} use in daily operations`}
                  options={transactionCurrencies}
                  error={methods.formState.errors.functionalCurrencies?.message as string}
                  disabled={methods.formState.isSubmitting || !editable}
                />
              )}
            />
            <Controller
              control={methods.control}
              name="industry"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Industry"
                  placeholder="Please select..."
                  options={industries.map((x) => ({ label: x, value: x }))}
                  error={methods.formState.errors.industry?.message as string}
                  disabled={methods.formState.isSubmitting || !editable}
                />
              )}
            />
            {editable ? (
              <>
                <MonetInput
                  {...methods.register("address.addressLine1")}
                  id="address.addressLine1"
                  label="Address line 1"
                  error={methods.formState.errors.address?.addressLine1?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
                <MonetInput
                  {...methods.register("address.addressLine2")}
                  id="address.addressLine2"
                  label="Address line 2"
                  error={methods.formState.errors.address?.addressLine2?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
                <MonetInput
                  {...methods.register("address.city")}
                  id="address.city"
                  label="City"
                  error={methods.formState.errors.address?.city?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
                <MonetInput
                  {...methods.register("address.postcode")}
                  id="address.postcode"
                  label="Postcode"
                  error={methods.formState.errors.address?.postcode?.message as string}
                  disabled={methods.formState.isSubmitting}
                />
              </>
            ) : (
              <MonetInput label="Address" value={concatAddress} readOnly={true} />
            )}
          </div>
        </form>
      </FormProvider>
    </MonetCard>
  );
};

export default ProfileDetailsSection;
