import { yupResolver } from "@hookform/resolvers/yup";
import MonetAlert from "components/tailwind/MonetAlert";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import MonetCheckbox from "components/tailwind/form/MonetCheckbox";
import MonetDatePicker from "components/tailwind/form/MonetDatePicker";
import MonetFileInput from "components/tailwind/form/MonetFileInput";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetMoneyInput from "components/tailwind/form/MonetMoneyInput";
import MonetSwitch from "components/tailwind/form/MonetSwitch";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { Campaign } from "shared/types/eep.contract.types";
import { formatSortCode } from "shared/utils/beneficiaryDetails";
import { CreateInvoiceFormObject, createInvoiceSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { DUE_DATE_FORMAT } from "utils/date";
import { useCreateInvoiceMutation } from "views/campaigns/graphql/mutations.generated";
import { uploadCampaignFiles } from "views/campaigns/utils";

type AddInvoiceDrawerProps = {
  refetchInvoices(): void;
  refetchCampaign(): void;
  campaign?: Campaign;
};

const AddInvoiceDrawer: React.FC<AddInvoiceDrawerProps> = ({ campaign, refetchInvoices, refetchCampaign }) => {
  const [selectedDeliverables, setSelectedDeliverables] = useState<number[]>();

  const [createInvoice] = useCreateInvoiceMutation();

  const { user } = useSessionProvider();
  const { closeModal } = useModal();
  const { partner } = useSessionProvider();

  const onSubmit = async (formData: CreateInvoiceFormObject) => {
    if (campaign) {
      try {
        const res = await uploadCampaignFiles<CreateInvoiceFormObject["invoiceDocument"]>(formData.invoiceDocument, user, campaign.campaignId);
        if (res.length > 0) {
          await createInvoice({
            variables: {
              campaignId: campaign.campaignId,
              invoice: {
                invoiceValue: formData.invoiceValue,
                invoiceDocument: res[0].file,
                deliverables: formData.deliverables!,
                invoiceNumber: formData.invoiceNumber,
                dueDate: dayjs(formData.dueDate[0]).format(DUE_DATE_FORMAT),
                termsAndConditions: formData.termsAndConditions,
              },
            },
          })
            .then(() => {
              refetchInvoices();
              refetchCampaign();
              resetComponent();
              toast.success("Success", { description: "Invoice has been created. You can now view it in the invoice section below" });
            })
            .catch((err: Error | any) => {
              resetComponent();
              toast.error("Request failed", { description: err.message });
            });
        }
      } catch (err: Error | any) {
        toast.error("Request failed", { description: "We were unable to upload the invoice file. Please try again" });
        resetComponent();
      }
    }
  };

  const methods = useForm<CreateInvoiceFormObject>({
    mode: "onTouched",
    resolver: yupResolver(createInvoiceSchema) as Resolver<any>,
    defaultValues: {
      invoiceDocument: [
        {
          file: "",
        },
      ],
      invoiceValue: "",
      invoiceNumber: "",
      dueDate: [],
      deliverables: undefined,
      termsAndConditions: false,
    },
  });

  const resetComponent = () => {
    methods.reset();
    closeModal();
    setSelectedDeliverables(undefined);
  };

  const handleDeliverableSelection = (deliverableId: number) => {
    setSelectedDeliverables((prevSelectedDeliverables) => {
      if (!prevSelectedDeliverables) {
        return [deliverableId];
      } else if (prevSelectedDeliverables.includes(deliverableId)) {
        return prevSelectedDeliverables.filter((id) => id !== deliverableId);
      } else {
        return [...prevSelectedDeliverables, deliverableId];
      }
    });
  };

  const deliverables = useMemo(() => {
    if (campaign && campaign.deliverables) {
      return campaign.deliverables.map((deliverable) => {
        if (deliverable.status === "COMPLETED") {
          return <MonetSwitch label={deliverable.name} showInRow={true} showLabels={false} size="small" onClick={() => handleDeliverableSelection(deliverable.deliverableId)} />;
        } else return null;
      });
    } else return null;
  }, [campaign]);

  const collectionAccount = useMemo(() => {
    return partner?.collectionAccounts.find((account) => account.currency === campaign?.campaignValue.currency);
  }, [partner]);

  useEffect(() => {
    if (selectedDeliverables) {
      methods.setValue("deliverables", selectedDeliverables, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    }
  }, [selectedDeliverables]);

  const isDeliverables = useMemo(() => {
    if (campaign?.deliverables) {
      return campaign.deliverables.find((deliverable) => deliverable.status === "COMPLETED");
    } else return false;
  }, [campaign]);

  return (
    <TailwindDrawer
      title="Invoice"
      subtitle="Invoices provide a way to secure funding for your campaign. Once underwriting is successfully completed, you can access funds up to the invoice's value."
      onCloseCallback={resetComponent}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col h-full justify-between">
          {!isDeliverables ? (
            <MonetAlert
              title="No deliverables"
              message="Please add deliverables to this campaign or mark existing deliverables as 'completed' in order to add an invoice for funding.
              Deliverables are used to qualify invoices, therefor proof of client sign off is also required."
              variant="warning"
            />
          ) : (
            <div className="flex flex-col gap-2">
              {collectionAccount && (
                <MonetAlert variant="info" title="Important" message="Please ensure that the invoice is made out to the account below:">
                  <div className="mt-2">
                    <MonetLabelledItem label="Account Name" value={collectionAccount.accountName} />
                    <MonetLabelledItem label="Account number" value={collectionAccount.accountNumber} />
                    <MonetLabelledItem label="Bank" value={collectionAccount.bankName} />
                    <MonetLabelledItem label="Sort code" value={formatSortCode(collectionAccount.sortCode)} />
                    <MonetLabelledItem label="IBAN" value={collectionAccount.iban} />
                    <MonetLabelledItem label="Swift / bic" value={collectionAccount.swiftCode} />
                  </div>
                </MonetAlert>
              )}
              <MonetFileInput
                {...methods.register(`invoiceDocument.0.file`)}
                disabled={methods.formState.isSubmitting}
                error={methods.formState.errors.invoiceDocument?.[0]?.file?.message as string}
                label="Invoice document"
              />
              <MonetInput
                {...methods.register("invoiceNumber")}
                label="Invoice number"
                error={methods.formState.errors.invoiceNumber?.message as string}
                disabled={methods.formState.isSubmitting}
                id="invoiceNumber"
                tooltip="Invoice number that you use for reconciling"
              />
              <MonetMoneyInput currency={campaign?.campaignValue.currency} name="invoiceValue" label="Invoice value" />
              <Controller
                control={methods.control}
                name="dueDate"
                render={({ field: { ...inputProps } }) => (
                  <MonetDatePicker
                    key="dueDate"
                    inputProps={inputProps}
                    disabled={methods.formState.isSubmitting}
                    label="Due date"
                    pickerOptions={{
                      minDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
                      mode: "single",
                    }}
                    error={methods.formState.errors.dueDate?.message as string}
                  />
                )}
              />
              <div className="text-sm flex flex-col gap-1 mb-4">
                <p className="font-semibold">Deliverables</p>
                <p>Please select the campaign deliverables associated with this invoice. Once the client signs off on the deliverables, you can apply for financing</p>
                <MonetBorderedCardContent className="max-h-[150px] overflow-auto mt-2">{deliverables}</MonetBorderedCardContent>
                <p className="text-sm text-red-500 mt-2">{methods.formState.errors.deliverables?.message as string}</p>
              </div>
              <MonetCheckbox label="Financing terms" {...methods.register("termsAndConditions")} id="termsAndConditions" className="mr-10 mt-1 self-start">
                By requesting financing for this invoice, you agree to the EarlyPay Agreement and Standard Conditions. Upon MONET's approval to finance this invoice, you agree to
                assign this invoice and its rights to MONET.
              </MonetCheckbox>
            </div>
          )}
          <MonetDrawerButtons
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isDirty || !methods.formState.isValid}
            loading={methods.formState.isSubmitting}
            onCancelCallback={resetComponent}
            isPassive={!isDeliverables}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default AddInvoiceDrawer;
