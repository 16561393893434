import { ReactComponent as ChartColumnIcon } from "assets/tailwind/icons/chart-column.svg";
import { ReactComponent as FileTextIcon } from "assets/tailwind/icons/file-text.svg";
import { ReactComponent as DashboardIcon } from "assets/tailwind/icons/layout-dashboard.svg";
import { ReactComponent as MenuIcon } from "assets/tailwind/icons/menu.svg";
import { ReactComponent as EarlyPayIcon } from "assets/tailwind/icons/receipt-pound-sterling.svg";
import { ReactComponent as SettingsIcon } from "assets/tailwind/icons/settings-2.svg";
import { ReactComponent as UsersIcon } from "assets/tailwind/icons/users.svg";
import { ReactComponent as MonetLogo } from "assets/tailwind/monet-logo.svg";
import AccountDropdown from "components/Impersonate/Impersonate";
import useAppProvider from "contexts/AppProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { HSOverlay } from "preline/preline";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "routing/paths";
import { isInAdminContext, isInPartnerContext } from "utils/User";
import { CAMPAIGNS_PATH } from "views/campaigns/CampaignsPath";
import { ORGANISATION_PATH, ORGANISATION_PROFILE_PATH } from "views/organisation/OrganisationPath";
import { PARTNERS_LIST_ALL_PATH, PARTNERS_PATH } from "views/partners/PartnersPath";
import { PAYOUTS_PATH, PAYOUT_LIST_ALL_PATH } from "views/payouts/PayoutPath";
import { SETTINGS_BASE_RATES_ADMIN, SETTINGS_DAILY_FEES, SETTINGS_PATH } from "views/settings/SettingsPath";
import { USER_PATH, USER_PROFILE_PATH } from "views/user/UserPath";
import MonetNavAccordion from "../MonetAccordion";
import MonetBadge from "../MonetBadge";
import MonetButton from "../MonetButton";
import MonetNavLinkItem from "../MonetNavLinkItem";

type ViewLayoutProps = PropsWithChildren & {
  helmet: string;
};

const ViewLayout: React.FC<ViewLayoutProps> = ({ children, helmet }) => {
  const { user } = useSessionProvider();
  const navigate = useNavigate();
  const { isMobile } = useAppProvider();

  useEffect(() => {
    HSOverlay.autoInit();
  }, []);

  const handleNavigation = useCallback(
    (path: string) => {
      navigate(path);
      if (isMobile) {
        location.reload();
      }
    },
    [isMobile],
  );

  const impersonationWarning = useMemo(() => {
    if (user.isImpersonating) {
      return (
        <div className="bg-red-100 border border-red-800 rounded-lg flex items-center px-1.5 py-1 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500">
          <span>
            Impersonating: <span className="font-semibold">{user.partner}</span>
          </span>
        </div>
      );
    } else return null;
  }, [user.isImpersonating]);

  return (
    <>
      <Helmet>
        <title>{helmet} - MONET</title>
      </Helmet>
      {/* Header */}
      <header className="lg:ms-[259px] sticky top-0 inset-x-0 z-[1] bg-[#f8fafc] border-b border-gray-200 dark:bg-neutral-900 dark:border-neutral-700">
        <div className="flex justify-between gap-3 flex-wrap lg:justify-end basis-full items-center w-full py-2 px-5 sm:px2.5" aria-label="Global">
          <div className="lg:hidden text-lg font-semibold text-monet-blue dark:text-white">
            <Link to={DASHBOARD_PATH}>
              <MonetLogo width={100} />
            </Link>
          </div>
          <div className="flex gap-3 flex-wrap">
            {impersonationWarning}
            <div className="border-e border-gray-200 w-px mx-3 dark:border-neutral-700"></div>
            <AccountDropdown />
            <MonetButton variant="white" size="x-small" className="lg:hidden" data-hs-overlay="#nav-sidebar" aria-controls="nav-sidebar" aria-label="Toggle navigation">
              <MenuIcon />
            </MonetButton>
          </div>
        </div>
      </header>
      {/* End Header */}
      {/* Navigation sidebar */}
      <aside
        id="nav-sidebar"
        className="hs-overlay hidden [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-200 transform w-[260px] hs-overlay-backdrop-open:bg-neutral-600/50 hs-overlay-backdrop-open:top-[50px] hs-overlay-backdrop-open:w-full hs-overlay-backdrop-open:h-full top-[50px] fixed bottom-0 start-0 z-[1] bg-white border-e border-gray-200 lg:block lg:top-0 lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-neutral-800 dark:border-neutral-700"
      >
        <div className="flex flex-col h-full max-h-full py-3">
          <header className="px-4 pb-5 pt-1 text-monet-blue dark:text-white hidden lg:block">
            {/* Logo */}
            <Link to={DASHBOARD_PATH}>
              <MonetLogo />
            </Link>
            {/* End Logo */}
          </header>
          {/* Content */}
          <div className="h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
            {/* Nav */}
            <nav className="hs-accordion-group p-3 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
              <ul className="space-y-1.5">
                <MonetNavLinkItem onClick={() => handleNavigation(DASHBOARD_PATH)} icon={<DashboardIcon />} id="dashboard">
                  Dashboard
                </MonetNavLinkItem>
                <MonetNavLinkItem onClick={() => handleNavigation(CAMPAIGNS_PATH)} id="campaigns" icon={<FileTextIcon />}>
                  Campaigns
                </MonetNavLinkItem>
                <MonetNavLinkItem onClick={() => handleNavigation(PAYOUTS_PATH + PAYOUT_LIST_ALL_PATH)} id="payouts" icon={<EarlyPayIcon />}>
                  Payouts
                </MonetNavLinkItem>
                {user.isAdministrator && isInAdminContext(user) && (
                  <MonetNavLinkItem onClick={() => handleNavigation(PARTNERS_PATH + PARTNERS_LIST_ALL_PATH)} id="partners" icon={<UsersIcon />}>
                    Partners
                  </MonetNavLinkItem>
                )}
                <MonetNavAccordion label="Settings" id="settings" icon={<SettingsIcon />}>
                  {isInPartnerContext(user) && (
                    <MonetNavLinkItem onClick={() => handleNavigation(ORGANISATION_PATH + ORGANISATION_PROFILE_PATH)} id="organisation">
                      Organisation
                    </MonetNavLinkItem>
                  )}
                  <MonetNavLinkItem onClick={() => handleNavigation(USER_PATH + USER_PROFILE_PATH)} id="profile">
                    Profile
                  </MonetNavLinkItem>
                  {user.isAdministrator && isInAdminContext(user) && (
                    <MonetNavLinkItem onClick={() => handleNavigation(SETTINGS_PATH + SETTINGS_BASE_RATES_ADMIN)} id="base-rates">
                      Base rates
                    </MonetNavLinkItem>
                  )}
                  {isInPartnerContext(user) && (
                    <MonetNavLinkItem onClick={() => handleNavigation(SETTINGS_PATH + SETTINGS_DAILY_FEES)} id="daily-fees">
                      Daily fees
                    </MonetNavLinkItem>
                  )}
                </MonetNavAccordion>
                <MonetNavLinkItem variant="comingSoon" onClick={() => handleNavigation(PAYOUTS_PATH + PAYOUT_LIST_ALL_PATH)} id="payouts" icon={<ChartColumnIcon />}>
                  Reports <MonetBadge variant="green">Coming soon</MonetBadge>
                </MonetNavLinkItem>
              </ul>
            </nav>
            {/* End Nav */}
          </div>
          {/* End Content */}
        </div>
      </aside>
      {/* End navigation sidebar */}
      {/* Main content */}
      <main id="content" className="lg:ps-[259px] min-h-[calc(100vh-51px)] flex bg-[#fcfdfd] dark:bg-neutral-700">
        <div className="py-3 px-3 sm:py-5 sm:px-7 min-h-full flex flex-col flex-1 w-full gap-6">{children}</div>
      </main>
      {/* End main content */}
    </>
  );
};

export default ViewLayout;
