import * as Types from 'shared/generated/ep3.graphql.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPayoutsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPayoutsQuery = { __typename?: 'Query', getPayouts: any };

export type GetPayoutQueryVariables = Types.Exact<{
  payoutId: Types.Scalars['String']['input'];
}>;


export type GetPayoutQuery = { __typename?: 'Query', getPayout: any };

export type GetCampaignsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCampaignsQuery = { __typename?: 'Query', getCampaigns: any };

export type GetConfigurationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConfigurationsQuery = { __typename?: 'Query', getConfigurations: any };

export type GetConfigurationQueryVariables = Types.Exact<{
  configurationId: Types.Scalars['String']['input'];
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetConfigurationQuery = { __typename?: 'Query', getConfiguration: any };

export type GetBanksQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String']['input'];
}>;


export type GetBanksQuery = { __typename?: 'Query', getBanks: any };

export type GetZoneCodesQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String']['input'];
}>;


export type GetZoneCodesQuery = { __typename?: 'Query', getZoneCodes: Array<{ __typename?: 'BorderlessZoneCode', name: string, code: string }> };

export type GetSupportedCountriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSupportedCountriesQuery = { __typename?: 'Query', getSupportedCountries: Array<{ __typename?: 'BorderlessCountry', name: string, code: string, currency: Array<string> }> };

export type GetPayeeFieldsQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String']['input'];
  accountType: Types.BorderlessAccountType;
}>;


export type GetPayeeFieldsQuery = { __typename?: 'Query', getPayeeFields: any };

export type GetPayeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPayeesQuery = { __typename?: 'Query', getPayees: any };

export type GetPartnerFeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPartnerFeesQuery = { __typename?: 'Query', GetPartnerFees: Array<{ __typename?: 'GetPartnerFeesResponse', baseRate: number, margin: number, currency: string }> };

export type GetInvoicesByCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
  partnerId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetInvoicesByCampaignQuery = { __typename?: 'Query', getInvoicesByCampaign: any };

export type GetPayoutsByCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['String']['input'];
}>;


export type GetPayoutsByCampaignQuery = { __typename?: 'Query', getPayoutsByCampaign: any };


export const GetPayoutsDocument = gql`
    query getPayouts {
  getPayouts
}
    `;

/**
 * __useGetPayoutsQuery__
 *
 * To run a query within a React component, call `useGetPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayoutsQuery(baseOptions?: Apollo.QueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
      }
export function useGetPayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
        }
export function useGetPayoutsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPayoutsQuery, GetPayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayoutsQuery, GetPayoutsQueryVariables>(GetPayoutsDocument, options);
        }
export type GetPayoutsQueryHookResult = ReturnType<typeof useGetPayoutsQuery>;
export type GetPayoutsLazyQueryHookResult = ReturnType<typeof useGetPayoutsLazyQuery>;
export type GetPayoutsSuspenseQueryHookResult = ReturnType<typeof useGetPayoutsSuspenseQuery>;
export type GetPayoutsQueryResult = Apollo.QueryResult<GetPayoutsQuery, GetPayoutsQueryVariables>;
export const GetPayoutDocument = gql`
    query getPayout($payoutId: String!) {
  getPayout(payoutId: $payoutId)
}
    `;

/**
 * __useGetPayoutQuery__
 *
 * To run a query within a React component, call `useGetPayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayoutQuery({
 *   variables: {
 *      payoutId: // value for 'payoutId'
 *   },
 * });
 */
export function useGetPayoutQuery(baseOptions: Apollo.QueryHookOptions<GetPayoutQuery, GetPayoutQueryVariables> & ({ variables: GetPayoutQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayoutQuery, GetPayoutQueryVariables>(GetPayoutDocument, options);
      }
export function useGetPayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayoutQuery, GetPayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayoutQuery, GetPayoutQueryVariables>(GetPayoutDocument, options);
        }
export function useGetPayoutSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPayoutQuery, GetPayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayoutQuery, GetPayoutQueryVariables>(GetPayoutDocument, options);
        }
export type GetPayoutQueryHookResult = ReturnType<typeof useGetPayoutQuery>;
export type GetPayoutLazyQueryHookResult = ReturnType<typeof useGetPayoutLazyQuery>;
export type GetPayoutSuspenseQueryHookResult = ReturnType<typeof useGetPayoutSuspenseQuery>;
export type GetPayoutQueryResult = Apollo.QueryResult<GetPayoutQuery, GetPayoutQueryVariables>;
export const GetCampaignsDocument = gql`
    query getCampaigns {
  getCampaigns
}
    `;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
      }
export function useGetCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export function useGetCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options);
        }
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsSuspenseQueryHookResult = ReturnType<typeof useGetCampaignsSuspenseQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<GetCampaignsQuery, GetCampaignsQueryVariables>;
export const GetConfigurationsDocument = gql`
    query getConfigurations {
  getConfigurations
}
    `;

/**
 * __useGetConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
      }
export function useGetConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export function useGetConfigurationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export type GetConfigurationsQueryHookResult = ReturnType<typeof useGetConfigurationsQuery>;
export type GetConfigurationsLazyQueryHookResult = ReturnType<typeof useGetConfigurationsLazyQuery>;
export type GetConfigurationsSuspenseQueryHookResult = ReturnType<typeof useGetConfigurationsSuspenseQuery>;
export type GetConfigurationsQueryResult = Apollo.QueryResult<GetConfigurationsQuery, GetConfigurationsQueryVariables>;
export const GetConfigurationDocument = gql`
    query getConfiguration($configurationId: String!, $partnerId: String) {
  getConfiguration(configurationId: $configurationId, partnerId: $partnerId)
}
    `;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *      configurationId: // value for 'configurationId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetConfigurationQuery(baseOptions: Apollo.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables> & ({ variables: GetConfigurationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
      }
export function useGetConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export function useGetConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(GetConfigurationDocument, options);
        }
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationLazyQueryHookResult = ReturnType<typeof useGetConfigurationLazyQuery>;
export type GetConfigurationSuspenseQueryHookResult = ReturnType<typeof useGetConfigurationSuspenseQuery>;
export type GetConfigurationQueryResult = Apollo.QueryResult<GetConfigurationQuery, GetConfigurationQueryVariables>;
export const GetBanksDocument = gql`
    query getBanks($countryCode: String!) {
  getBanks(countryCode: $countryCode)
}
    `;

/**
 * __useGetBanksQuery__
 *
 * To run a query within a React component, call `useGetBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanksQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetBanksQuery(baseOptions: Apollo.QueryHookOptions<GetBanksQuery, GetBanksQueryVariables> & ({ variables: GetBanksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
      }
export function useGetBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
        }
export function useGetBanksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBanksQuery, GetBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBanksQuery, GetBanksQueryVariables>(GetBanksDocument, options);
        }
export type GetBanksQueryHookResult = ReturnType<typeof useGetBanksQuery>;
export type GetBanksLazyQueryHookResult = ReturnType<typeof useGetBanksLazyQuery>;
export type GetBanksSuspenseQueryHookResult = ReturnType<typeof useGetBanksSuspenseQuery>;
export type GetBanksQueryResult = Apollo.QueryResult<GetBanksQuery, GetBanksQueryVariables>;
export const GetZoneCodesDocument = gql`
    query getZoneCodes($countryCode: String!) {
  getZoneCodes(countryCode: $countryCode) {
    name
    code
  }
}
    `;

/**
 * __useGetZoneCodesQuery__
 *
 * To run a query within a React component, call `useGetZoneCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZoneCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZoneCodesQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetZoneCodesQuery(baseOptions: Apollo.QueryHookOptions<GetZoneCodesQuery, GetZoneCodesQueryVariables> & ({ variables: GetZoneCodesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZoneCodesQuery, GetZoneCodesQueryVariables>(GetZoneCodesDocument, options);
      }
export function useGetZoneCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZoneCodesQuery, GetZoneCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZoneCodesQuery, GetZoneCodesQueryVariables>(GetZoneCodesDocument, options);
        }
export function useGetZoneCodesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetZoneCodesQuery, GetZoneCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetZoneCodesQuery, GetZoneCodesQueryVariables>(GetZoneCodesDocument, options);
        }
export type GetZoneCodesQueryHookResult = ReturnType<typeof useGetZoneCodesQuery>;
export type GetZoneCodesLazyQueryHookResult = ReturnType<typeof useGetZoneCodesLazyQuery>;
export type GetZoneCodesSuspenseQueryHookResult = ReturnType<typeof useGetZoneCodesSuspenseQuery>;
export type GetZoneCodesQueryResult = Apollo.QueryResult<GetZoneCodesQuery, GetZoneCodesQueryVariables>;
export const GetSupportedCountriesDocument = gql`
    query getSupportedCountries {
  getSupportedCountries {
    name
    code
    currency
  }
}
    `;

/**
 * __useGetSupportedCountriesQuery__
 *
 * To run a query within a React component, call `useGetSupportedCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportedCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>(GetSupportedCountriesDocument, options);
      }
export function useGetSupportedCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>(GetSupportedCountriesDocument, options);
        }
export function useGetSupportedCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>(GetSupportedCountriesDocument, options);
        }
export type GetSupportedCountriesQueryHookResult = ReturnType<typeof useGetSupportedCountriesQuery>;
export type GetSupportedCountriesLazyQueryHookResult = ReturnType<typeof useGetSupportedCountriesLazyQuery>;
export type GetSupportedCountriesSuspenseQueryHookResult = ReturnType<typeof useGetSupportedCountriesSuspenseQuery>;
export type GetSupportedCountriesQueryResult = Apollo.QueryResult<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>;
export const GetPayeeFieldsDocument = gql`
    query getPayeeFields($countryCode: String!, $accountType: BorderlessAccountType!) {
  getPayeeFields(countryCode: $countryCode, accountType: $accountType)
}
    `;

/**
 * __useGetPayeeFieldsQuery__
 *
 * To run a query within a React component, call `useGetPayeeFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayeeFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayeeFieldsQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useGetPayeeFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetPayeeFieldsQuery, GetPayeeFieldsQueryVariables> & ({ variables: GetPayeeFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayeeFieldsQuery, GetPayeeFieldsQueryVariables>(GetPayeeFieldsDocument, options);
      }
export function useGetPayeeFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayeeFieldsQuery, GetPayeeFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayeeFieldsQuery, GetPayeeFieldsQueryVariables>(GetPayeeFieldsDocument, options);
        }
export function useGetPayeeFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPayeeFieldsQuery, GetPayeeFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayeeFieldsQuery, GetPayeeFieldsQueryVariables>(GetPayeeFieldsDocument, options);
        }
export type GetPayeeFieldsQueryHookResult = ReturnType<typeof useGetPayeeFieldsQuery>;
export type GetPayeeFieldsLazyQueryHookResult = ReturnType<typeof useGetPayeeFieldsLazyQuery>;
export type GetPayeeFieldsSuspenseQueryHookResult = ReturnType<typeof useGetPayeeFieldsSuspenseQuery>;
export type GetPayeeFieldsQueryResult = Apollo.QueryResult<GetPayeeFieldsQuery, GetPayeeFieldsQueryVariables>;
export const GetPayeesDocument = gql`
    query getPayees {
  getPayees
}
    `;

/**
 * __useGetPayeesQuery__
 *
 * To run a query within a React component, call `useGetPayeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayeesQuery(baseOptions?: Apollo.QueryHookOptions<GetPayeesQuery, GetPayeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayeesQuery, GetPayeesQueryVariables>(GetPayeesDocument, options);
      }
export function useGetPayeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayeesQuery, GetPayeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayeesQuery, GetPayeesQueryVariables>(GetPayeesDocument, options);
        }
export function useGetPayeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPayeesQuery, GetPayeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayeesQuery, GetPayeesQueryVariables>(GetPayeesDocument, options);
        }
export type GetPayeesQueryHookResult = ReturnType<typeof useGetPayeesQuery>;
export type GetPayeesLazyQueryHookResult = ReturnType<typeof useGetPayeesLazyQuery>;
export type GetPayeesSuspenseQueryHookResult = ReturnType<typeof useGetPayeesSuspenseQuery>;
export type GetPayeesQueryResult = Apollo.QueryResult<GetPayeesQuery, GetPayeesQueryVariables>;
export const GetPartnerFeesDocument = gql`
    query GetPartnerFees {
  GetPartnerFees {
    baseRate
    margin
    currency
  }
}
    `;

/**
 * __useGetPartnerFeesQuery__
 *
 * To run a query within a React component, call `useGetPartnerFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerFeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPartnerFeesQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
      }
export function useGetPartnerFeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
        }
export function useGetPartnerFeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>(GetPartnerFeesDocument, options);
        }
export type GetPartnerFeesQueryHookResult = ReturnType<typeof useGetPartnerFeesQuery>;
export type GetPartnerFeesLazyQueryHookResult = ReturnType<typeof useGetPartnerFeesLazyQuery>;
export type GetPartnerFeesSuspenseQueryHookResult = ReturnType<typeof useGetPartnerFeesSuspenseQuery>;
export type GetPartnerFeesQueryResult = Apollo.QueryResult<GetPartnerFeesQuery, GetPartnerFeesQueryVariables>;
export const GetInvoicesByCampaignDocument = gql`
    query getInvoicesByCampaign($campaignId: String!, $partnerId: String) {
  getInvoicesByCampaign(campaignId: $campaignId, partnerId: $partnerId)
}
    `;

/**
 * __useGetInvoicesByCampaignQuery__
 *
 * To run a query within a React component, call `useGetInvoicesByCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesByCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesByCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetInvoicesByCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables> & ({ variables: GetInvoicesByCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>(GetInvoicesByCampaignDocument, options);
      }
export function useGetInvoicesByCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>(GetInvoicesByCampaignDocument, options);
        }
export function useGetInvoicesByCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>(GetInvoicesByCampaignDocument, options);
        }
export type GetInvoicesByCampaignQueryHookResult = ReturnType<typeof useGetInvoicesByCampaignQuery>;
export type GetInvoicesByCampaignLazyQueryHookResult = ReturnType<typeof useGetInvoicesByCampaignLazyQuery>;
export type GetInvoicesByCampaignSuspenseQueryHookResult = ReturnType<typeof useGetInvoicesByCampaignSuspenseQuery>;
export type GetInvoicesByCampaignQueryResult = Apollo.QueryResult<GetInvoicesByCampaignQuery, GetInvoicesByCampaignQueryVariables>;
export const GetPayoutsByCampaignDocument = gql`
    query getPayoutsByCampaign($campaignId: String!) {
  getPayoutsByCampaign(campaignId: $campaignId)
}
    `;

/**
 * __useGetPayoutsByCampaignQuery__
 *
 * To run a query within a React component, call `useGetPayoutsByCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayoutsByCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayoutsByCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetPayoutsByCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables> & ({ variables: GetPayoutsByCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>(GetPayoutsByCampaignDocument, options);
      }
export function useGetPayoutsByCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>(GetPayoutsByCampaignDocument, options);
        }
export function useGetPayoutsByCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>(GetPayoutsByCampaignDocument, options);
        }
export type GetPayoutsByCampaignQueryHookResult = ReturnType<typeof useGetPayoutsByCampaignQuery>;
export type GetPayoutsByCampaignLazyQueryHookResult = ReturnType<typeof useGetPayoutsByCampaignLazyQuery>;
export type GetPayoutsByCampaignSuspenseQueryHookResult = ReturnType<typeof useGetPayoutsByCampaignSuspenseQuery>;
export type GetPayoutsByCampaignQueryResult = Apollo.QueryResult<GetPayoutsByCampaignQuery, GetPayoutsByCampaignQueryVariables>;