import { FC, InputHTMLAttributes, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { twMerge } from "tailwind-merge";

type MonetPhoneNumberInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  error?: string;
  isOptional?: boolean;
};
const MonetPhoneNumberInput: FC<MonetPhoneNumberInputProps> = ({ name, error, label, isOptional, ...props }) => {
  const { control } = useFormContext();

  const errorStyling = error ? "!border-red-500 !ring !ring-red-100 !ring-offset-0 !focus:ring-red-500 !focus:ring !focus:ring-red-100 !focus:outline-offset-0" : undefined;

  const handleChange = useCallback(
    (value: string) => {
      const prefix = value.length ? "+" : "";
      return prefix + value;
    },
    [name],
  );

  return (
    <div className="flex-1">
      {label && (
        <label htmlFor={name} className="block font-semibold text-sm mb-2 text-gray-800 dark:text-white">
          {label}
          {isOptional && <sup className="text-gray-600 font-light">(Optional)</sup>}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <PhoneInput
            autoFormat={false}
            placeholder=""
            country="gb"
            preferredCountries={["gb", "us", "au"]}
            containerClass="text-gray-800 test-base sm:text-sm"
            inputClass={twMerge(
              "border !border-gray-200 !dark:border-neutral-700  !rounded-lg !py-3.5 !px-4 !pl-[58px] !text-sm !focus:border-blue-500 !focus:ring-blue-500 !w-full",
              errorStyling,
            )}
            dropdownClass="!shadow-lg border !border-neutral-700 !rounded-lg !z-21 !overflow-y-scroll"
            inputProps={{
              id: name,
              ...props,
            }}
            value={value}
            onChange={(e) => onChange(handleChange(e))}
            onBlur={onBlur}
            specialLabel=""
          />
        )}
      />
      <p className="text-sm text-red-500 mt-2">{error}</p>
    </div>
  );
};

export default MonetPhoneNumberInput;
