import MissingProfileInformationBanner from "components/onboarding/MissingProfileInformationBanner";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import { useSessionProvider } from "contexts/SessionProvider";
import { HSTabs } from "preline/preline";
import { useEffect } from "react";
import { isPartnerVerified, restrictPlatformAccess } from "utils/Partner";
import Billing from "./components/tabs/Billing";
import EarlyPayCollectionAccounts from "./components/tabs/EarlyPaySettings/EarlyPayCollectionAccounts";
import EarlyPaySettings from "./components/tabs/EarlyPaySettings/EarlyPaySettings";
import OrganisationDetails from "./components/tabs/OrganisationDetails";
import UsersTable from "./components/tabs/UsersTable";

const OrganisationProfileNew = () => {
  const { partner, user } = useSessionProvider();

  const env = process.env.REACT_APP_ENV_NAME;

  useEffect(() => {
    HSTabs.autoInit();
  }, []);

  return (
    <>
      <MissingProfileInformationBanner />
      <MonetViewTitle>Organisation profile</MonetViewTitle>
      <nav className="flex space-x-1" aria-label="Tabs" role="tablist">
        <button
          type="button"
          className="hs-tab-active:bg-gray-100 hs-tab-active:text-gray-800 hs-tab-active:hover:text-gray-800 dark:hs-tab-active:bg-neutral-700 dark:hs-tab-active:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-500 dark:hover:text-neutral-400 active"
          id="organisation-details-tab"
          data-hs-tab="#organisation-details-section"
          aria-controls="organisation-details-section"
          role="tab"
        >
          Details
        </button>
        {env !== "Sandbox" && (
          <button
            type="button"
            className="hs-tab-active:bg-gray-100 hs-tab-active:text-gray-800 hs-tab-active:hover:text-gray-800 dark:hs-tab-active:bg-neutral-700 dark:hs-tab-active:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-500 dark:hover:text-neutral-400"
            id="billing-tab"
            data-hs-tab="#billing-section"
            aria-controls="billing-section"
            role="tab"
          >
            Billing
          </button>
        )}
        {!restrictPlatformAccess(partner, user) && (
          <>
            {isPartnerVerified(partner, user) && (
              <button
                type="button"
                className="hs-tab-active:bg-gray-100 hs-tab-active:text-gray-800 hs-tab-active:hover:text-gray-800 dark:hs-tab-active:bg-neutral-700 dark:hs-tab-active:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-500 dark:hover:text-neutral-400"
                id="organisation-earlypay-settings-tab"
                data-hs-tab="#organisation-earlypay-settings-section"
                aria-controls="organisation-earlypay-settings-section"
                role="tab"
              >
                EarlyPay Settings
              </button>
            )}
            <button
              type="button"
              className="hs-tab-active:bg-gray-100 hs-tab-active:text-gray-800 hs-tab-active:hover:text-gray-800 dark:hs-tab-active:bg-neutral-700 dark:hs-tab-active:text-white py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm font-medium text-center text-gray-500 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-500 dark:hover:text-neutral-400"
              id="organisation-users-tab"
              data-hs-tab="#organisation-users-section"
              aria-controls="organisation-users-section"
              role="tab"
            >
              Users
            </button>
          </>
        )}
      </nav>
      <div className="flex-1">
        <div id="organisation-details-section" role="tabpanel" aria-labelledby="organisation-details-tab" className="h-full">
          <OrganisationDetails />
        </div>
        {env !== "sandbox" && (
          <div id="billing-section" className="hidden h-full" role="tabpanel" aria-labelledby="billing-tab">
            <Billing />
          </div>
        )}
        {!restrictPlatformAccess(partner, user) && (
          <>
            {isPartnerVerified(partner, user) && (
              <div id="organisation-earlypay-settings-section" className="hidden h-full" role="tabpanel" aria-labelledby="organisation-earlypay-settings-tab">
                <div className="flex flex-col gap-6">
                  <EarlyPaySettings />
                  <EarlyPayCollectionAccounts />
                </div>
              </div>
            )}
            <div id="organisation-users-section" className="hidden h-full" role="tabpanel" aria-labelledby="organisation-users-tab">
              <UsersTable />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrganisationProfileNew;
