import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ReactComponent as CrossIcon } from "assets/tailwind/icons/cross.svg";
import MonetBorderedCardContent from "components/tailwind/MonetBorderedCardContent";
import MonetButton from "components/tailwind/MonetButton";
import MonetLabelledItem from "components/tailwind/MonetLabelledItem";
import { useModal } from "contexts/ModalProvider";
import { useMemo } from "react";
import { formatBsbNumber, formatSortCode } from "shared/utils/beneficiaryDetails";
import { getPayeeInitials } from "utils/Payees";
import { enumToNiceString } from "utils/strings";

type BeneficiaryDetailsModelProps = {
  payee: Components.Schemas.Payee;
};

const BeneficiaryDetailsModel: React.FC<BeneficiaryDetailsModelProps> = ({ payee }) => {
  const { closeModal } = useModal();

  const formattedRoutingNumber = useMemo(() => {
    if (payee.routingNumber) {
      if (payee.country === "AUS") {
        return formatBsbNumber(payee.routingNumber);
      } else {
        return formatSortCode(payee.routingNumber);
      }
    } else return undefined;
  }, [payee]);

  const concatAddress = useMemo(() => {
    return [payee.addressLine1, payee.addressLine2, payee.city, payee.postalCode].filter(Boolean).join(", ");
  }, [payee]);

  return (
    <Dialog transition open={true} onClose={() => closeModal()} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          transition
          className="relative flex flex-col bg-white border shadow-sm max-w-[800px] w-full rounded-lg pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70"
        >
          <div className="bg-gray-200 rounded-t-lg flex justify-end p-2 h-[75px] align-top absolute top-0 left-0 w-full items-start">
            <MonetButton size="x-small" variant="white" onClick={() => closeModal()} className="z-20">
              <CrossIcon />
            </MonetButton>
          </div>
          <div className="flex flex-col justify-center z-10 mt-[20px] p-4 items-center">
            <div className="flex justify-center items-center rounded-full w-[75px] h-[75px] bg-white border border-gray-300">
              <p className="text-2xl font-semibold">{getPayeeInitials(`${payee.firstName} ${payee.lastName}`)}</p>
            </div>
            <p className="mt-2 font-semibold text-sm">{payee.email}</p>
            <p className="text-sm">{payee.payeeAlias}</p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 p-4 gap-4">
            <MonetBorderedCardContent>
              <MonetLabelledItem label="Name" value={`${payee.firstName} ${payee.lastName}`} />
              <MonetLabelledItem label="Date of Birth" value={payee.dateOfBirth} />
              <MonetLabelledItem label="Country" value={payee.country} />
              <MonetLabelledItem label="Address" value={concatAddress} />
              <MonetLabelledItem label="Zone code" value={payee.zoneCode} />
              <MonetLabelledItem label="Phone number" value={payee.phoneNumber} />
              <MonetLabelledItem label="Payee type" value={payee.payeeType && enumToNiceString(payee.payeeType)} />
            </MonetBorderedCardContent>
            <MonetBorderedCardContent>
              <MonetLabelledItem label="Bank name" value={payee.bankName} />
              <MonetLabelledItem label="Bank account type" value={payee.bankAccountType} />
              <MonetLabelledItem label="Account type" value={payee.accountType} />
              <MonetLabelledItem label="Account number" value={payee.accountNumber} />
              <MonetLabelledItem label="Sort code" value={formattedRoutingNumber} />
              <MonetLabelledItem label="Swift/IBAN" value={payee.bankSwiftBic} />
              <MonetLabelledItem label="Currency" value={payee.currency} />
            </MonetBorderedCardContent>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default BeneficiaryDetailsModel;
