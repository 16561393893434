import { useModal } from "contexts/ModalProvider";
import { useCallback } from "react";
import MonetButton from "./MonetButton";

type MonetDrawerButtonsProps = {
  drawerId?: string;
  cancelDisabled: boolean;
  submitDisabled: boolean;
  loading?: boolean;
  onCancelCallback?(): void;
  isPassive?: boolean;
};

const MonetDrawerButtons: React.FC<MonetDrawerButtonsProps> = ({ submitDisabled, cancelDisabled, loading, onCancelCallback, isPassive }) => {
  const { closeModal } = useModal();

  const handleCancel = useCallback(() => {
    onCancelCallback && onCancelCallback();
    closeModal();
  }, [onCancelCallback]);

  return (
    <div className="flex flex-row gap-4 mt-4">
      <MonetButton onClick={handleCancel} variant="outlined" color="gray-outlined" className="flex-1" disabled={cancelDisabled}>
        {isPassive ? "Close" : "Cancel"}
      </MonetButton>
      {!isPassive && (
        <MonetButton type="submit" className="flex-1" loading={loading} disabled={submitDisabled}>
          Confirm
        </MonetButton>
      )}
    </div>
  );
};

export default MonetDrawerButtons;
