import { Currency, Money } from "@monet-money/money-type";
import { Box, FormControl, FormLabel, InputAdornment, TextField as MuiTextField } from "@mui/material";
import { TextField, TextFieldProps } from "mui-rff";
import React, { useMemo } from "react";
import { useForm } from "react-final-form";
import { handleWheel } from "utils/inputs";
import getCurrencySymbol from "utils/strings";

type MoneyTextFieldProps = {
  currency: Components.Schemas.Currency;
  name: string;
  fullWidth?: boolean;
  label?: string;
} & TextFieldProps;

const MoneyTextField: React.FC<MoneyTextFieldProps> = ({ currency, name, label, fullWidth = true, ...props }) => {
  const { change, getFieldState } = useForm();

  const [fieldValue, setFieldValue] = React.useState<number | string>("");

  React.useEffect(() => {
    const fieldVal = getFieldState(name)?.value || 0;
    setFieldValue(fieldVal);
  }, [getFieldState(name)]);

  const fieldState = getFieldState(name);
  const errors = !fieldState?.pristine && fieldState?.modified ? fieldState?.error : undefined;

  const fieldErrors = useMemo(() => {
    if (!errors) {
      return undefined;
    }
    const fieldError = "Invalid_amount";
    const message = "Total must be a number and greater than 0";
    if (typeof errors == "string") {
      return errors == fieldError ? message : errors;
    }

    return (errors as string[]).map((x) => (x == fieldError ? message : x));
  }, [errors]);

  const transformValueToStorageType = (value: string) => {
    const initialMoney = new Money(value, currency as Currency);
    return Money.toStorageType(initialMoney);
  };

  const inputValue = React.useMemo(() => {
    const moneyValue = new Money(fieldValue, currency as Currency);
    return (+fieldValue ?? 0) / moneyValue.precision;
  }, [fieldValue]);
  return (
    <FormControl fullWidth={fullWidth}>
      {label && <FormLabel>{label}</FormLabel>}
      <Box display="none">
        <TextField name={name} type="number" autoComplete="off" />
      </Box>
      <MuiTextField
        {...props}
        placeholder="0"
        name={name + "-text-field"}
        type="number"
        error={!!fieldErrors}
        autoComplete="off"
        helperText={fieldErrors ?? props.helperText}
        onChange={(event) => {
          const storageValue = transformValueToStorageType(event.target.value);
          change(name, storageValue.amount);
        }}
        onWheel={handleWheel}
        InputProps={{
          startAdornment: <InputAdornment position="start">{currency && !props.disabled && getCurrencySymbol(currency)}</InputAdornment>,
          inputProps: {
            value: fieldValue == 0 ? "" : inputValue,
            min: 0,
            step: "0.1",
          },
        }}
      />
    </FormControl>
  );
};

export default MoneyTextField;
