import { VariantProps, cva } from "class-variance-authority";
import clsx from "clsx";
import { InputHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import MonetToolTip from "../MonetTooltip";

export type MonetInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  VariantProps<typeof variants> & {
    label?: string;
    error?: string;
    secondaryLabel?: React.ReactNode;
    endAdornment?: React.ReactNode | string;
    startAdornment?: React.ReactNode | string;
    helperText?: React.ReactNode;
    showInRow?: boolean;
    isOptional?: boolean;
    tooltip?: string;
  };

const MonetInput: React.FC<MonetInputProps> = forwardRef<HTMLInputElement, MonetInputProps>(
  ({ error, label, className, variant, size, children, secondaryLabel, endAdornment, helperText, startAdornment, showInRow, isOptional, readOnly, tooltip, ...props }, ref) => {
    const errorStyling = error && !readOnly ? "border-red-500 ring ring-red-100 ring-offset-0 focus:ring-red-500 focus:ring focus:ring-red-100 focus:outline-offset-0" : undefined;
    const startAdornmentStyling = startAdornment ? "ps-8" : undefined;
    const arrangement = showInRow ? "flex flex-col sm:flex-row justify-between gap-4" : "flex flex-col sm:flex-col";
    const readOnlyStyling = readOnly ? "text-gray-500" : "";

    return (
      <div className="w-full">
        <div className={arrangement}>
          {(label || secondaryLabel) && (
            <div className="flex flex-col sm:flex-row justify-between mb-2 text-sm">
              <label htmlFor={label} className="block font-semibold text-gray-800 dark:text-white">
                {label}
                {isOptional && <sup className="text-gray-600 font-light">(Optional)</sup>}
                {tooltip && <MonetToolTip text={tooltip} />}
              </label>
              <div className="block text-sm text-gray-500 dark:text-neutral-500">{secondaryLabel}</div>
            </div>
          )}
          <div className="relative">
            <input
              ref={ref}
              readOnly={readOnly}
              className={twMerge(clsx(variants({ variant, size }), errorStyling, startAdornmentStyling, readOnlyStyling, className))}
              {...props}
            />
            {startAdornment && <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-1 ps-3">{startAdornment}</div>}
            {endAdornment && (
              <div className="absolute inset-y-0 end-0 text-gray-600 flex items-center pointer-events-none pe-3 [&>svg]:w-[18px] [&>svg]:h-[18px]">{endAdornment}</div>
            )}
          </div>
        </div>
        {helperText && <p className="text-xs ps-1 pt-1">{helperText}</p>}
        {!readOnly && error && <p className="text-sm text-red-500 mt-2">{error}</p>}
      </div>
    );
  },
);

export default MonetInput;

const variants = cva(
  "block w-full text-base sm:text-sm border rounded-lg disabled:opacity-70 disabled:pointer-events-none disabled:bg-gray-100 dark:bg-transparent dark:disabled:bg-transparent",
  {
    variants: {
      variant: {
        default:
          "border-gray-200 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600",
      },
      size: {
        small: "py-2 px-3.5",
        default: "py-3.5 px-4 h-[50px]",
        large: "py-6 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);
