import { Money, MoneyStorage } from "@monet-money/money-type";
import CompleteProfileBanner from "components/onboarding/CompleteProfileBanner";
import MonetMetric from "components/tailwind/MonetMetric";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import MonetAreaChart from "components/tailwind/charts/MonetAreaChart";
import ViewLayout from "components/tailwind/templates/ViewLayout";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Aggregation } from "shared/generated/ep3.graphql.types";
import { AGGREGATE_DATE_FORMAT } from "shared/utils/string";
import { MetricsData } from "utils/Aggregation";
import DashboardTables from "./components/DashboardTables";
import { useGetAggregationQuery } from "./graphql/queries.generated";

const Dashboard = () => {
  const [metrics, setMetrics] = useState<MetricsData>({
    fundingBalance: undefined,
    paymentsCompleted: undefined,
    totalRevenue: undefined,
    pendingPayouts: undefined,
  });
  const [chartData, setChartData] = useState([{}]);
  const { data: aggregationData, loading: isAggregationLoading } = useGetAggregationQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    if (aggregationData?.getAggregation) {
      const aggregations = aggregationData?.getAggregation as Aggregation;
      const currentMonthAndYear = dayjs().format(AGGREGATE_DATE_FORMAT);

      const paymentsCompletedThisMonth = aggregations.paymentsCompleted?.find((x) => x!.date === currentMonthAndYear) || undefined;
      const fundingBalance = aggregations.fundingBalance ? (aggregations.fundingBalance as MoneyStorage) : undefined;
      const totalRevenueThisMonth = aggregations.totalRevenue?.find((x) => x!.date === currentMonthAndYear) || undefined;

      const revenueSeries = aggregations.totalRevenue
        ?.map((item) => {
          const revenueData = Money.fromStorageType(item!.value as MoneyStorage);
          const formattedRevenueData = revenueData.format(false, false);
          if (formattedRevenueData === "0.00") return null;

          return {
            x: item!.date,
            y: formattedRevenueData,
            currency: item!.value.currency,
            label: revenueData.format(),
          };
        })
        .filter(Boolean);

      setChartData(revenueSeries as Array<Object>);
      setMetrics({
        fundingBalance: fundingBalance,
        paymentsCompleted: paymentsCompletedThisMonth,
        totalRevenue: totalRevenueThisMonth,
        pendingPayouts: aggregations?.pendingPayouts || 0,
      });
    }
  }, [aggregationData]);

  return (
    <ViewLayout helmet="Dashboard">
      <CompleteProfileBanner />
      <MonetViewTitle>Dashboard</MonetViewTitle>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
        <MonetMetric
          loading={isAggregationLoading}
          label="Funding"
          tooltip="This is the total value of all payouts in status beneficiary paid"
          value={Money.fromStorageType(metrics.fundingBalance).format(true, true)}
        />
        <MonetMetric
          loading={isAggregationLoading}
          label="Payments completed"
          tooltip={`The total number of payments completed in ${dayjs(metrics.paymentsCompleted?.date).format("MMMM")}`}
          value={metrics.paymentsCompleted?.value}
        />
        <MonetMetric
          loading={isAggregationLoading}
          label="Total revenue"
          tooltip={`The total amount of revenue for payouts completed in ${dayjs(metrics.totalRevenue?.date).format("MMMM")}`}
          value={Money.fromStorageType(metrics.totalRevenue?.value as MoneyStorage).format(true, true)}
        />
        <MonetMetric loading={isAggregationLoading} label="Pending payouts" tooltip="The total number of currently pending payouts" value={metrics.pendingPayouts} />
      </div>
      <DashboardTables />
      <MonetAreaChart
        title="Revenue"
        xaxisLabel="Month"
        yaxisLabel="Total revenue"
        series={[
          {
            name: "Revenue",
            data: chartData,
          },
        ]}
      />
    </ViewLayout>
  );
};

export default Dashboard;
