import { yupResolver } from "@hookform/resolvers/yup";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetFileInput from "components/tailwind/form/MonetFileInput";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { Resolver, useForm } from "react-hook-form";
import { toast } from "sonner";
import { processImageFile } from "utils/file";
import { useUpdatePartnerLogoMutation } from "views/organisation/graphql/mutations.generated";
import { OrganisationLogoUploadFormObject, organisationLogoUploadSchema } from "../ValidationSchemas/uploadLogoSchema";

const OrganisationLogoDrawer: React.FC = () => {
  const { refetchCurrentPartner } = useSessionProvider();
  const { closeModal } = useModal();

  const [updatePartnerLogo] = useUpdatePartnerLogoMutation();

  const onSubmit = async (formData: OrganisationLogoUploadFormObject) => {
    const logo = await processImageFile(formData.logo[0]);
    try {
      await updatePartnerLogo({
        variables: {
          logo: {
            file: logo.filePath!,
            name: logo.name!,
            type: logo.type!,
          },
        },
      });
      toast.success("Success", { description: "Organisation logo has been updated" });
      refetchCurrentPartner();
      resetComponent();
    } catch (error: any) {
      toast.error("Request failed", { description: "The logo could not be updated. Please try again" + error.message });
    }
  };

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm<OrganisationLogoUploadFormObject>({
    mode: "onTouched",
    resolver: yupResolver(organisationLogoUploadSchema) as Resolver<OrganisationLogoUploadFormObject>,
    defaultValues: {
      logo: undefined,
    },
  });

  const resetComponent = () => {
    closeModal();
    reset();
  };

  return (
    <TailwindDrawer title="Change logo" subtitle="Upload a logo below. The file must be PNG, JPG or JPEG and no larger than 400x400" onCloseCallback={resetComponent}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-4">
        <div className="flex flex-col gap-4">
          <MonetFileInput size="small" {...register("logo")} disabled={isSubmitting} error={errors.logo?.message as string} />
        </div>
        <MonetDrawerButtons cancelDisabled={isSubmitting} submitDisabled={isSubmitting || !isValid} loading={isSubmitting} onCancelCallback={resetComponent} />
      </form>
    </TailwindDrawer>
  );
};

export default OrganisationLogoDrawer;
