import { yupResolver } from "@hookform/resolvers/yup";
import MonetDrawerButtons from "components/tailwind/MonetDrawerButtons";
import MonetInput from "components/tailwind/form/MonetInput";
import MonetPhoneNumberInput from "components/tailwind/form/MonetPhoneNumberInput";
import TailwindDrawer from "components/tailwind/headlessTailwind/TailwindDrawer";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useModal } from "contexts/ModalProvider";
import { useSessionProvider } from "contexts/SessionProvider";
import { useMemo } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { Campaign } from "shared/types/eep.contract.types";
import { UpdateClientFormObject, updateClientSchema } from "shared/utils/validators";
import { toast } from "sonner";
import { Invoice } from "utils/Invoice";
import { isInAdminContext } from "utils/User";
import { getAllCountries } from "utils/country";
import { useUpdateClientMutation } from "views/campaigns/graphql/mutations.generated";

type ClientDetailsDrawerProps = {
  refetchCampaign(): void;
  invoices?: Invoice[];
  campaign?: Campaign;
};

const EditClientDetailsDrawer: React.FC<ClientDetailsDrawerProps> = ({ campaign, refetchCampaign, invoices }) => {
  const [updateClient] = useUpdateClientMutation();

  const { closeModal } = useModal();
  const { user } = useSessionProvider();

  const onSubmit = async (formData: UpdateClientFormObject) => {
    if (campaign) {
      try {
        await updateClient({
          variables: {
            client: {
              legalName: formData.legalName,
              countryOfRegistration: formData.countryOfRegistration,
              email: formData.email,
              jobTitle: formData.jobTitle,
              name: formData.name,
              phoneNumber: formData.phoneNumber,
            },
            campaignId: campaign.campaignId,
          },
        });
        toast.success("Success", { description: "The client information has been updated" });
        refetchCampaign();
        resetComponent();
      } catch (error) {
        toast.error("Request failed", { description: "The client information could not be updated. Please try again" });
      }
    }
  };

  const methods = useForm<UpdateClientFormObject>({
    mode: "onTouched",
    resolver: yupResolver(updateClientSchema) as Resolver<UpdateClientFormObject>,
    values: {
      legalName: campaign?.client?.legalName ?? "",
      countryOfRegistration: campaign?.client?.countryOfRegistration ?? "",
      name: campaign?.client?.contact?.name ?? "",
      email: campaign?.client?.contact?.email ?? "",
      phoneNumber: campaign?.client?.contact?.phoneNumber ?? "",
      jobTitle: campaign?.client?.contact?.jobTitle ?? "",
    },
  });

  const resetComponent = () => {
    closeModal();
    methods.reset();
  };

  const activeOrSettledInvoice = useMemo(() => {
    if (invoices) {
      return invoices.find((invoice) => invoice.funding && ["ACTIVE", "SETTLED"].includes(invoice.funding?.fundingStatus)) ? true : false;
    } else return false;
  }, [invoices]);

  const isEditable = useMemo(() => {
    if (campaign && campaign.campaignStatus === "COMPLETED") {
      return false;
    } else return true;
  }, [campaign, activeOrSettledInvoice]);

  return (
    <TailwindDrawer title="Client" subtitle="View and edit the client and client contact information for this campaign" onCloseCallback={resetComponent}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col flex-1 justify-between mt-4">
          <div className="flex flex-col gap-4">
            <MonetInput
              {...methods.register("legalName")}
              label="Client: Legal name"
              error={methods.formState.errors.legalName?.message as string}
              disabled={methods.formState.isSubmitting || activeOrSettledInvoice}
              id="legalName"
              readOnly={!isEditable || isInAdminContext(user)}
            />
            <Controller
              control={methods.control}
              name="countryOfRegistration"
              render={({ field: { ...inputProps } }) => (
                <TailwindSelectInput
                  inputProps={inputProps}
                  label="Client: Country of registration"
                  options={getAllCountries()}
                  placeholder="Please select..."
                  error={methods.formState.errors.countryOfRegistration?.message as string}
                  disabled={methods.formState.isSubmitting || !isEditable || isInAdminContext(user) || activeOrSettledInvoice}
                />
              )}
            />
            <MonetInput
              {...methods.register("name")}
              label="Contact: Full name"
              error={methods.formState.errors.name?.message as string}
              disabled={methods.formState.isSubmitting}
              id="name"
              readOnly={!isEditable || isInAdminContext(user)}
            />
            <MonetPhoneNumberInput
              label="Contact: Mobile number"
              name="phoneNumber"
              error={methods.formState.errors.phoneNumber?.message as string}
              disabled={methods.formState.isSubmitting}
              readOnly={!isEditable || isInAdminContext(user)}
              isOptional={true}
            />
            <MonetInput
              {...methods.register("email")}
              label="Contact: Email address"
              error={methods.formState.errors.email?.message as string}
              disabled={methods.formState.isSubmitting}
              id="email"
              readOnly={!isEditable || isInAdminContext(user)}
            />
            <MonetInput
              {...methods.register("jobTitle")}
              label="Contact: Job title"
              error={methods.formState.errors.jobTitle?.message as string}
              disabled={methods.formState.isSubmitting}
              id="jobTitle"
              readOnly={!isEditable || isInAdminContext(user)}
              isOptional={true}
            />
          </div>
          <MonetDrawerButtons
            cancelDisabled={methods.formState.isSubmitting}
            submitDisabled={methods.formState.isSubmitting || !methods.formState.isValid || !isEditable}
            isPassive={!isEditable || isInAdminContext(user)}
            loading={methods.formState.isSubmitting}
            onCancelCallback={resetComponent}
          />
        </form>
      </FormProvider>
    </TailwindDrawer>
  );
};

export default EditClientDetailsDrawer;
